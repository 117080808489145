import React, { useState } from 'react';
import auth from './auth';
import './style.css';
import DraftsIcon from '@material-ui/icons/Drafts';
import LockIcon from '@material-ui/icons/Lock';
import Api from '../../store/api';
import { AUTHENTICATION } from '../../store/endpoint';
import { CircularProgress } from '@material-ui/core';
import { AppLogo } from '../../common-components/images';

function App(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loader, setloader] = useState(false);
    const [error, setError] = useState('');

    const loginFx = () => {
        if (username !== '' && password !== '') {
            let postData = {
                username: username,
                password: password
            }
            const success = (res) => {
                setloader(false);
                const { access, refresh } = res.response
                auth.login(access, refresh, () => {
                    props.history.push('/');
                })
            }
            const failed = () => {
                setloader(false);
                setError('Invalid username or password');
            }
            setloader(true);
            Api('post', AUTHENTICATION, postData, props.history, success, failed, false)
        } else {
            if (username === '') {
                setError('Username field is empty');
            } else if (password === '') {
                setError('Password field is empty')
            };
        }

    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='login-container'>
                <div className="login-layout">
                    <div className="login-form-layout">
                        <div className="login-head">
                            <img src={AppLogo} alt="" />
                            {/* <h1>EBK STUDY</h1> */}
                            <h1>Indira Gandhi Group of Institutions</h1>
                            <p>Login to your account</p>
                        </div>

                        <div className="login-form-div">
                            <div className="login-form-section">
                                <div className="input-field">
                                    <DraftsIcon className="icons" />
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        onChange={txt => setUsername(txt.target.value)}
                                    />
                                </div>
                                <div className="input-field">
                                    <LockIcon className="icons" />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={txt => setPassword(txt.target.value)}
                                    />
                                </div>
                                <p style={{ color: 'red' }}>{error}</p>
                                <button
                                    style={{ border: 0 }}
                                    className={loader ? "login-form-btn disabled-btn" : "login-form-btn"}
                                    onClick={() => loginFx()}
                                >
                                    {
                                        loader ? <CircularProgress size={18} color="inherit" /> : "Login"
                                    }

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
