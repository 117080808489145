import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PersonAdd, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../../common-components/navbar';
import { useHistory } from 'react-router';
import { fetch_agencies } from '../store/action';
import { selectCollegeDatas } from '../../college/store/slice';
import { selectAgencyDatas } from '../store/slice';
import { selectFinanceDatas } from '../../finance/store/slice';


const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));


function NavbarAgency({ modalAction }) {


    const route_history = useHistory();
    const dispatch = useDispatch();
    const { arr_colleges } = useSelector(selectCollegeDatas);
    const { agency_filter, agency_search, agency_financial_yr } = useSelector(selectAgencyDatas);
    const { financialYrList } = useSelector(selectFinanceDatas)

    const classes = useStyles();


    return (
        <Navbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Agency{financialYrList && agency_financial_yr ? `(${financialYrList.find((el)=> el.id===agency_financial_yr).year})` : ''}</h4>
            </div>

            <div className="app-nav-right">
                <button
                    className="app-common-btn"
                    onClick={() => modalAction(true)}
                >
                    <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                </button>

                <div style={{ margin: '0px 10px' }}>
                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={agency_filter}
                            className={classes.resize}
                            onChange={txt => {
                                //setSort(txt.target.value)
                                fetch_agencies(route_history, dispatch, 1, [], txt.target.value, agency_search);
                            }}
                        >
                            <MenuItem value={'all'}>Filter - All</MenuItem>
                            {
                                arr_colleges.map((item, key) => {
                                    const { id, name } = item
                                    return (
                                        <MenuItem key={key} value={id}>{name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>

                <div style={{ margin: '0px 10px' }}>
                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={agency_financial_yr===null ? 'all' : agency_financial_yr}
                            className={classes.resize}
                            onChange={txt => {
                                //setSort(txt.target.value)
                                fetch_agencies(route_history, dispatch, 1, [], agency_filter, agency_search, true, false, txt.target.value==='all' ? null : txt.target.value);
                            }}
                        >
                            <MenuItem value={'all'}>Fiancial Year Filter - All</MenuItem>
                            {
                                financialYrList.map((item, key) => {
                                    const { id, year } = item
                                    return (
                                        <MenuItem key={key} value={id}>{year}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>


                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fetch_agencies(route_history, dispatch, 1, [], agency_filter, keyword, true, false, agency_financial_yr);
                            } else {
                                fetch_agencies(route_history, dispatch, 1, [], agency_filter, '', true, false, agency_financial_yr);
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
        </Navbar>
    )
}

export default NavbarAgency
