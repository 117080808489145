
import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import Scrollview from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { fetch_staffs, delete_staff, status_change_staff } from '../store/action';
import { selectStaffDatas } from '../store/slice';
import { selectCollegeDatas } from '../../college/store/slice';
import StaffModal from './staff_modal';

function ListAgency() {

    const route_history = useHistory();
    const dispatch = useDispatch();
    const { arr_staff, staff_loader, staff_page, staff_pagination, staff_search } = useSelector(selectStaffDatas);
    const { arr_colleges } = useSelector(selectCollegeDatas);

    const [Modal, setModal] = useState(false);
    const [SelectedStaff, setSelectedStaff] = useState({});
    const [Name, setName] = useState('');
    const [Designation, setDesignation] = useState('admin');
    const [PhoneNo, setPhoneNo] = useState('');
    const [Email, setEmail] = useState('');
    const [College, setCollege] = useState('');
    const [Password, setPassword] = useState('');
    const [EmailError, setEmailError] = useState('');

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_staff(route_history, dispatch, id);
            }
        })
    }


    const status_change_fx = (id, button_status) => {
        let post_data = {
            active_status: true,
            enabled_status: button_status,
        }
        status_change_staff(route_history, dispatch, post_data, id);
    }


    const Scrolling = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight;
        if (bottom) {
            if (staff_pagination) {
                let page_no_val = staff_page + 1;
                fetch_staffs(route_history, dispatch, page_no_val, arr_staff, staff_search, false)
            }
        }
    }

    return (
        <div className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>Staff Id</td>
                            <td style={{}}>Name</td>
                            <td style={{ width: 150 }}>Phone</td>
                            <td style={{ width: 200 }}>Email</td>
                            <td style={{ width: 150 }}>Designation</td>
                            <td style={{ width: 150 }}>College</td>
                            <td style={{ width: 100 }}>Manage</td>
                            <td style={{ width: 150 }}>Status</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => Scrolling(e)}
                    style={{ height: '70vh', position: 'relative' }}>
                    {!staff_loader ? (
                        <>
                            {arr_staff.length > 0 ? (
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_staff.map((item, key) => {
                                                const { id, name, email, primary_phone, user_type, college_id, enabled_status } = item;
                                                let college_name = '';
                                                if (college_id) {
                                                    const college_data = arr_colleges.find(clg_item => clg_item.id === college_id);
                                                    college_data ? college_name = college_data.name : college_name = '';
                                                }
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 80 }}>STF{id}</td>
                                                        <td style={{}}>{name}</td>
                                                        <td style={{ width: 150 }}>{primary_phone}</td>
                                                        <td style={{ width: 200 }}>{email}</td>
                                                        <td style={{ width: 150 }}>{user_type}</td>
                                                        <td style={{ width: 150 }}>{college_name}</td>
                                                        <td style={{ width: 100 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Edit
                                                                    onClick={() => {
                                                                        setSelectedStaff(item)
                                                                        setName(name);
                                                                        setDesignation(user_type);
                                                                        setPhoneNo(primary_phone);
                                                                        setEmail(email);
                                                                        setCollege(college_id);
                                                                        setModal(true);
                                                                    }}
                                                                    style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                            </div>
                                                        </td>
                                                        <td style={{ width: 150 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                {
                                                                    enabled_status ? (
                                                                        <button
                                                                            onClick={() => status_change_fx(id, !enabled_status)}
                                                                            style={{ backgroundColor: 'red', height: 30, padding: 10 }} className="app-common-btn">Revoke</button>
                                                                    ) : (
                                                                        <button
                                                                            onClick={() => status_change_fx(id, !enabled_status)}
                                                                            style={{ backgroundColor: 'blue', height: 30, padding: 10 }} className="app-common-btn">Enable</button>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}
                </Scrollview>
            </div>
            <StaffModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedStaff}
                Name={Name}
                setName={setName}
                Designation={Designation}
                setDesignation={setDesignation}
                PhoneNo={PhoneNo}
                setPhoneNo={setPhoneNo}
                Email={Email}
                setEmail={setEmail}
                College={College}
                setCollege={setCollege}
                Password={Password}
                setPassword={setPassword}
                EmailError={EmailError}
                setEmailError={setEmailError}
            />
        </div>
    )
}

export default ListAgency
