import AppRoute from './app/modules/app';
import Sidebar from './app/common-components/sidebar';
import Login from './app/modules/login';
import { ProtectedRoute } from './app/modules/login/protectedRoute';
import { Route, Switch } from 'react-router';
import { AppLogo } from './app/common-components/images';
import './App.css';

function App() {

  console.log('Latest buld version 2.0');

  return (
    <div className="App">
      <Sidebar />
      <div className="app-layout">
        <div className="app-navbar">
          <img src={AppLogo} alt="" />
          <h1>Indira Gandhi Group of Institutions</h1>
        </div>
        <AppRoute />
      </div>

      
    </div>
  );
}

const AppProtectRouting = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/login" component={Login} />
        <ProtectedRoute
          path="/"
          component={App}
        />
      </Switch>
    </div>
  )
}

export default AppProtectRouting;
