import { createSlice } from '@reduxjs/toolkit';

export const feesSlice = createSlice({
    name: 'fees',
    initialState: {
        arr_fees: [],
        fees_page: 1,
        fees_search: '',
        fees_filter: 'all',
        fees_pagination: true,
        fees_filter_from_date: '',
        fees_filter_to_date: '',
        fees_loader: false,
        fees_list_loader: false,
        fees_general_info: {
            due_amount: 0,
            todays_fees: 0,
            total_paid_amount: 0,
            total_student: 0,
        },
    },
    reducers: {
        update_fees: (state, action) => {
            const { arr_response, general_infos, page_no, pagination, search, filter, filter_from_date, filter_to_date } = action.payload;
            state.arr_fees = arr_response;
            state.fees_general_info = general_infos;
            state.fees_page = page_no;
            state.fees_pagination = pagination;
            state.fees_search = search;
            state.fees_filter = filter;
            state.fees_filter_from_date = filter_from_date;
            state.fees_filter_to_date = filter_to_date;
            state.fees_loader = false;
            state.fees_list_loader = false;
        },

        update_fees_loader: (state, action) => {
            const { loader } = action.payload;
            state.fees_loader = loader;
        },

        update_fees_list_loader: (state, action) => {
            const { loader } = action.payload;
            state.fees_list_loader = loader;
        },

        add_single_fees: (state, action) => {
            const { new_data } = action.payload;
            state.arr_fees.unshift(new_data);
        },

        remove_single_fees: (state, action) => {
            const { fees_id } = action.payload;
            let latest_list = state.arr_fees.filter(item => item.id !== fees_id);
            state.arr_fees = latest_list;
        },

        // update_single_fees: (state, action) => {
        //     const { fees_id, new_data } = action.payload;
        //     let array_item = state.arr_feess.find(item => item.id === fees_id);
        //     let arra_item_index = state.arr_feess.indexOf(array_item);
        //     state.arr_feess[arra_item_index] = new_data;
        // },

    }
})

export const {
    update_fees,
    update_fees_loader,
    update_fees_list_loader,
    add_single_fees,
    remove_single_fees,
    
} = feesSlice.actions

export const selectFeesDatas = state => state.fees;

export default feesSlice.reducer;