import React, { useEffect, useRef, useState } from 'react'
import { CalendarTodayRounded, ExpandLessRounded, ExpandMoreRounded, PersonAdd, Print, Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';
import Scrollview from 'react-custom-scrollbars';
import Navbar from '../../../common-components/navbar';
import { Link, useHistory } from 'react-router-dom';
import { fetch_students, print_students_all_data } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollegeDatas } from '../../college/store/slice';
import { selectStudentsDatas } from '../store/slice';
import { CircularProgress } from '@material-ui/core';
import { BASE_MAIN } from '../../../store/endpoint';
import AcademicYearModal from './add_academic_year_modal';
import Api from '../../../store/api'



function NavbarAgency() {
    const route_history = useHistory();
    const dispatch = useDispatch();
    const { arr_colleges } = useSelector(selectCollegeDatas);
    const { 
        students_filter,
        students_search, 
        arr_all_course_list, 
        arr_all_addoncourse_list,
        total_students, 
        direct_students,
        agency_students,
        student_filter_seat, 
        student_filter_date, 
        student_filter_course, 
        student_filter_aadon_course,
        student_filter_batch
    } = useSelector(selectStudentsDatas);

    const [isOpen, setIsOpen] = useState(false);
    const [PrintButtonSpinner, setPrintButtonSpinner] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const dropdownRef = useRef(undefined);
    const buttonRef = useRef(undefined);

    const [coursesData, setCoursesData] = useState([])
    const [batchesData, setBatchesData] = useState([])
    const [addOnCoursesData, setAddOnCourseData] = useState([])
    const [YearData, setYearData] = useState('')
    const [Modal, setModal] = useState(false)

    const [showFilter, setShowFilter] = useState(false)

    const [openSection, setOpenSection] = useState({ college : true, course : false, batch : false, addOn : false  })

    useEffect(() => {

        const handleClickOutside = event => {
            const isDropdownClick =
                dropdownRef.current && dropdownRef.current.contains(event.target);
            const isButtonClick =
                buttonRef.current && buttonRef.current.contains(event.target);
            if (isDropdownClick || isButtonClick) {
                // If the ref is not defined or the user clicked on the menu, we don’t do anything.
                return;
            }
            // Otherwise we close the menu.
            setIsOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dropdownRef, buttonRef])


    const print_btn = () => {
        setPrintButtonSpinner(true);
        const success = () => setPrintButtonSpinner(false);
        const failed = () => setPrintButtonSpinner(false);
        print_students_all_data(route_history, students_filter, students_search, student_filter_seat, student_filter_date, student_filter_course, student_filter_aadon_course, success, failed)
    }

    const fetch_courses = (val) => {
        let api_url = `${BASE_MAIN}college/course/crud/college/${val}/?page=1&limit=10000`;
        const success = (res) => {
            const { response } = res;
            setCoursesData(response);
        }
        Api('get', api_url, '', route_history, success);
        fetch_batches(val,null)
    }
    const fetch_batches = (college, val) => {
        let api_url = `${BASE_MAIN}college/${college}/course/batch/crud/?page=1&limit=10000`;
    if (val!==null){
        api_url = `${BASE_MAIN}college/course/${val}/batch/crud/`;
        // api_url = `${api_url}?course=${val}`
    }
    const success = (res) => {
        const { response } = res;
        console.log(res)
        setBatchesData(response);
        if(val!==null)
            fetch_addon_courses(val);
    }
    Api('get', api_url, '', route_history, success);
    }
    const fetch_addon_courses = (val) => {
        let api_url = `${BASE_MAIN}college/course/${val}/addon/crud/?page=1&limit=10000`;
        const success = (res) => {
            const { response } = res;
            setAddOnCourseData(response);
        }
        Api('get', api_url, '', route_history, success);
    }

    const ChipDisplay = () =>{
        
        let chip1 = students_filter!=='all' ?
            arr_colleges && arr_colleges.find(el => el.id===students_filter)
                 ? <span>{arr_colleges.find(el => el.id===students_filter).name}</span> : null
            :   null
        let chip2 = student_filter_course!=='all' ?
            students_filter!=='all' ?
                coursesData && coursesData.find(el => el.id===student_filter_course) ?
                    <span>{coursesData.find(el => el.id===student_filter_course).name}</span> : null
            : 
                arr_all_course_list && arr_all_course_list.find(el => el.id===student_filter_course) ? 
                    <span>{arr_all_course_list.find(el => el.id===student_filter_course).name}</span> : null : null

        let chip3 = student_filter_batch!=='all' ?
            student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0) ?
                    batchesData && batchesData.find(el => el.id===student_filter_batch) ? 
                        <span>{batchesData.find(el => el.id===student_filter_batch).name}</span> : null : null : null

        let chip4 = student_filter_aadon_course!=='all' ?
            (((student_filter_course==='all' && students_filter==='all') && 
            arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0) ||
        (addOnCoursesData && addOnCoursesData.length!==0)) ?
                    addOnCoursesData && addOnCoursesData.find(el => el.id===student_filter_aadon_course) ?
                        <span>{addOnCoursesData.find(el => el.id===student_filter_aadon_course).name}</span> : null : null : null
        return (
            <div className='app-divChipMain'>
                {chip1}{chip2}{chip3}{chip4}
            </div>
        )
    }


    return (
        <Navbar>
            
            <h4>Students (<span style={{ fontSize: 20 }}>{total_students}</span>)</h4>
            <p style={{ fontSize: 14 }}>Direct: <span style={{ fontWeight: 'bold' }}>{direct_students}</span></p>
            <p style={{ fontSize: 14 }}>Agency: <span style={{ fontWeight: 'bold' }}>{agency_students}</span></p>

            <button className="app-common-btn" onClick={()=>{
                setYearData('');
                setModal(true);
            }} >
                <CalendarTodayRounded style={{ fontSize: 16, marginRight: 10 }} />Academic Year
            </button>
            <button
                onClick={() => print_btn()}
                style={{ width: 90 }}
                className={PrintButtonSpinner ? "app-common-btn app-btn-green disabled-btn" : "app-common-btn app-btn-green"}>
                {
                    PrintButtonSpinner ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <>
                            <Print style={{ fontSize: 16, marginRight: 10 }} />Print
                        </>
                    )
                }
            </button>

            <div className="app-nav-right">
                <Link to="/students/detail" style={{ textDecoration: 'none' }}>
                    <button className="app-common-btn">
                        <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                    </button>
                </Link>



                <div style={{ margin: '0px 10px', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10 }}>Date Filter</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (date) {
                                fetch_students(
                                    route_history, 
                                    dispatch, 
                                    1, 
                                    [], 
                                    students_filter, 
                                    '', 
                                    student_filter_seat, 
                                    date, 
                                    student_filter_course, 
                                    student_filter_aadon_course,
                                    true, false, student_filter_batch 
                                );
                            } else {
                                fetch_students(
                                    route_history, 
                                    dispatch, 
                                    1, 
                                    [], 
                                    students_filter, 
                                    '', 
                                    student_filter_seat, 
                                    '', 
                                    student_filter_course, 
                                    student_filter_aadon_course,
                                    true, false, student_filter_batch);
                            }
                        }}
                    />
                </div>



                <div style={{ margin: '0px 10px' }}>
                    <FormControl >
                        <div className="app-common-btn app-btn-green" style={{ position : 'relative' }} onClick={handleClick} ref={buttonRef}
                            onMouseEnter={()=>{
                                if(students_filter!=='all' || (students_filter!=='all' || student_filter_course!=='all') ||
                                    (student_filter_batch!=='all' && (student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0))) ||
                                    (student_filter_aadon_course!=='all' && 
                                    (((student_filter_course==='all' && students_filter==='all') && 
                                    arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0) ||
                                (addOnCoursesData && addOnCoursesData.length!==0))))
                                    setShowFilter(true)
                                else{
                                    setShowFilter(false)
                                }
                            }}
                            onMouseLeave={()=> setShowFilter(false)}
                        >
                            {   showFilter ?
                            <div className='app-divChip'>
                                <ChipDisplay />
                            </div>
                            :   null
                            }
                            <FilterListIcon style={{ color: 'white', cursor: 'pointer' }} />
                            <p style={{ color: 'white', fontSize: '13px', fontWeight: '480', cursor: 'pointer' }}>Filter</p>

                            {isOpen ? (
                                <div style={{ width: '40vh', color: '#000', paddingTop: 10, paddingBottom: 10 }} className="filter_layout" onClick={(e) => { e.stopPropagation(); }} ref={dropdownRef}>
                                    <Scrollview>
                                        <p style={{ padding: 10, fontSize: 14 }}>Seat Type Filter</p>
                                        <MenuItem onClick={() => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'all', 
                                                        student_filter_date, 
                                                        'all', 
                                                        'all',
                                                        true, false, 'all'
                                                    );
                                                }}>
                                            <label style={{ fontSize: '14px', width: '65vw', padding: '15px', paddingTop: 0, paddingBottom: 0 }}>All </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={student_filter_seat === 'all' ? true : false}
                                                onChange={txt => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'all', 
                                                        student_filter_date, 
                                                        'all', 
                                                        'all',
                                                        true, false, 'all'
                                                    );
                                                }}
                                            />
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'converted', 
                                                        student_filter_date, 
                                                        'all',
                                                        'all',
                                                        true, false, 'all'
                                                    );
                                                }}>
                                            <label style={{ fontSize: '14px', width: '65vw', padding: '15px', paddingTop: 0, paddingBottom: 0 }}>Converted Seats </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={student_filter_seat === 'converted' ? true : false}
                                                onChange={txt => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'converted', 
                                                        student_filter_date, 
                                                        'all',
                                                        'all',
                                                        true, false, 'all'
                                                    );
                                                }}
                                            />
                                        </MenuItem>

                                        <MenuItem onClick={() => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'inactive', 
                                                        student_filter_date, 
                                                        'all',
                                                        'all',
                                                        true, false, 'all');
                                                }}>
                                            <label style={{ fontSize: '14px', width: '65vw', padding: '15px', paddingTop: 0, paddingBottom: 0 }}>Inactive Students </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={student_filter_seat === 'inactive' ? true : false}
                                                onChange={txt => {
                                                    fetch_students(
                                                        route_history, 
                                                        dispatch, 
                                                        1, 
                                                        [], 
                                                        students_filter, 
                                                        '', 
                                                        'inactive', 
                                                        student_filter_date, 
                                                        'all',
                                                        'all',
                                                        true, false, 'all');
                                                }}
                                            />
                                        </MenuItem>

                                        <div style={{ margin: 10, height: 3, backgroundColor: 'grey', opacity: 0.3 }} />

                                        <p style={{ padding: 10, fontSize: 14, display : 'flex', alignItems : 'center' }} onClick={()=> setOpenSection({...openSection, college : !openSection.college}) }>
                                            <span>College Filter
                                                {arr_colleges ? `(${arr_colleges.length})` : ''}
                                            </span>
                                            {
                                                openSection.college ? <ExpandLessRounded/> : <ExpandMoreRounded />
                                            }
                                        </p>
                                        


                                        {   openSection.college ?
                                        <MenuItem onClick={() => {
                                            fetch_students(
                                                route_history, 
                                                dispatch, 
                                                1, 
                                                [], 
                                                'all', 
                                                '', 
                                                student_filter_seat, 
                                                student_filter_date, 
                                                'all',
                                                'all',
                                                true, false, 'all'
                                            );
                                        }}>
                                            <div
                                                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <label
                                                    style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                                    All Colleges
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer' }}
                                                    checked={students_filter === 'all' ? true : false}
                                                    onChange={txt => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            'all', 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            'all',
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}
                                                />
                                            </div>

                                        </MenuItem>
                                        :   null    }
                                        {   openSection.college ?
                                            arr_colleges.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        fetch_courses(id)
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            id, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            'all',
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={students_filter === id ? true : false}
                                                                onChange={txt => {
                                                                    fetch_courses(id)
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        id, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        'all',
                                                                        'all',
                                                                        true, false, 'all'
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                    </MenuItem>
                                                )
                                            })
                                            :   null
                                        }

                                        <div style={{ margin: 10, height: 3, backgroundColor: 'grey', opacity: 0.3 }} />
                                        <p style={{ padding: 10, fontSize: 14, display : 'flex', alignItems : 'center' }}onClick={()=> setOpenSection({...openSection, course : !openSection.course}) }>
                                            <span>Course Filter
                                                {students_filter==='all' && arr_all_course_list ? `(${arr_all_course_list.length})` : coursesData ? `(${coursesData.length})` : '' }
                                            </span>
                                            {
                                                openSection.course ? <ExpandLessRounded/> : <ExpandMoreRounded />
                                            }
                                        </p>
                                        {   openSection.course ?
                                        <MenuItem onClick={() => {
                                            fetch_students(
                                                route_history, 
                                                dispatch, 
                                                1, 
                                                [], 
                                                students_filter, 
                                                '', 
                                                student_filter_seat, 
                                                student_filter_date, 
                                                'all', 
                                                'all',
                                                true, false, 'all'
                                            );
                                        }}>
                                            <div
                                                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <label
                                                    style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                                    All Courses
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer' }}
                                                    checked={student_filter_course === 'all' ? true : false}
                                                    onChange={txt => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            'all', 
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </MenuItem>
                                        :   null
                                        }
                                        {   openSection.course ?
                                            students_filter==='all'     ?
                                            arr_all_course_list.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            id,
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={student_filter_course === id ? true : false}
                                                                onChange={txt => {
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        students_filter, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        id,
                                                                        'all',
                                                                        true, false, 'all'
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            coursesData.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        if(students_filter!=='all')
                                                            fetch_batches(students_filter,id)
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            id,
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={student_filter_course === id ? true : false}
                                                                onChange={txt => {
                                                                    if(students_filter!=='all')
                                                                        fetch_batches(students_filter,id)
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        students_filter, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        id,
                                                                        'all',
                                                                        true, false, 'all'
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                    </MenuItem>
                                                )
                                            })
                                            : null
                                        }


                                        {    
                                        (student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0)) ? 
                                                <div style={{ margin: 10, height: 3, backgroundColor: 'grey', opacity: 0.3 }} /> : null}
                                        {    
                                        (student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0)) ? 
                                                <p style={{ padding: 10, fontSize: 14, display : 'flex', alignItems : 'center' }} 
                                                    onClick={()=> setOpenSection({...openSection, batch : !openSection.batch}) }>
                                                    <span>
                                                        Batch Filter
                                                        {student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0)
                                                         ? `(${batchesData.length})` : '' }
                                                    </span>
                                                    {
                                                        openSection.batch ?
                                                            <ExpandLessRounded /> : <ExpandMoreRounded/>
                                                    }
                                                </p> : null}
                                        {
                                            openSection.batch && 
                                            student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0) ?
                                            <MenuItem onClick={() => {
                                                        
                                                fetch_students(
                                                    route_history, 
                                                    dispatch, 
                                                    1, 
                                                    [], 
                                                    students_filter, 
                                                    '', 
                                                    student_filter_seat, 
                                                    student_filter_date, 
                                                    'all', 
                                                    'all',
                                                    true, false, 'all'
                                                );
                                            }}>
                                            <div
                                                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <label
                                                    style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                                    All Batches
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer' }}
                                                    checked={student_filter_batch === 'all' ? true : false}
                                                    onChange={txt => {
                                                        
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            'all', 
                                                            'all',
                                                            true, false, 'all'
                                                        );
                                                    }}
                                                />
                                            </div>

                                            </MenuItem>
                                            :   null
                                        
                                        }
                                        {
                                            openSection.batch && 
                                            student_filter_course!=='all' && students_filter!=='all' && (batchesData && batchesData.length!==0) ?
                                            
                                            batchesData.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            student_filter_course,
                                                            student_filter_aadon_course,
                                                            true, false, id
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={student_filter_batch === id ? true : false}
                                                                onChange={txt => {
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        students_filter, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        student_filter_course,
                                                                        student_filter_aadon_course,
                                                                        true, false, id
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                    </MenuItem>
                                                )
                                            })
                                        
                                            : null
                                        }



                                        {   (((student_filter_course==='all' && students_filter==='all') && 
                                                arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0) ||
                                            (addOnCoursesData && addOnCoursesData.length!==0)) ?
                                            <div style={{ margin: 10, height: 3, backgroundColor: 'grey', opacity: 0.3 }} /> : null}
                                        {   (((student_filter_course==='all' && students_filter==='all') && 
                                                arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0) ||
                                            (addOnCoursesData && addOnCoursesData.length!==0)) ?

                                            <p style={{ padding: 10, fontSize: 14, display : 'flex', alignItems : 'center' }} 
                                                onClick={()=> setOpenSection({...openSection, addOn : !openSection.addOn}) }>
                                                <span>AddOn Course Filter
                                                {((
                                                (student_filter_course==='all' && students_filter==='all') 
                                                    && 
                                                    (arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0)) ||
                                            (addOnCoursesData && addOnCoursesData.length===0)) ? `(${arr_all_addoncourse_list.length})` : 
                                            addOnCoursesData ? `(${addOnCoursesData.length})` : '' }
                                                </span>
                                                {
                                                    openSection.addOn ?
                                                        <ExpandLessRounded/>    :   <ExpandMoreRounded/>
                                                }
                                            </p> : null}

                                        {   openSection.addOn &&
                                            (((student_filter_course==='all' && students_filter==='all') && arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0) ||
                                            (addOnCoursesData && addOnCoursesData.length!==0)) ?
                                        <MenuItem onClick={() => {
                                            fetch_students(
                                                route_history, 
                                                dispatch, 
                                                1, 
                                                [], 
                                                students_filter, 
                                                '', 
                                                student_filter_seat, 
                                                student_filter_date, 
                                                student_filter_course,
                                                'all',
                                                true, false, student_filter_batch
                                            );
                                        }}>
                                            <div
                                                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <label
                                                    style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                                    All Courses
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer' }}
                                                    checked={student_filter_aadon_course === 'all' ? true : false}
                                                    onChange={txt => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            student_filter_course,
                                                            'all',
                                                            true, false, student_filter_batch
                                                        );
                                                    }}
                                                />
                                            </div>

                                        </MenuItem>
                                        :   null}
                                        {
                                            openSection.addOn ?
                                            ((
                                                (student_filter_course==='all' && students_filter==='all') 
                                                    && 
                                                    (arr_all_addoncourse_list && arr_all_addoncourse_list.length!==0)) ||
                                            (addOnCoursesData && addOnCoursesData.length===0)) ?
                                            arr_all_addoncourse_list.map((item, key) => {
                                                const { id, name } = item;
                                                
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            student_filter_course, 
                                                            id,
                                                            true, false, student_filter_batch
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={student_filter_aadon_course === id ? true : false}
                                                                onChange={txt => {
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        students_filter, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        student_filter_course, 
                                                                        id,
                                                                        true, false, student_filter_batch
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            addOnCoursesData.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} onClick={() => {
                                                        fetch_students(
                                                            route_history, 
                                                            dispatch, 
                                                            1, 
                                                            [], 
                                                            students_filter, 
                                                            '', 
                                                            student_filter_seat, 
                                                            student_filter_date, 
                                                            student_filter_course, 
                                                            id,
                                                            true, false, student_filter_batch
                                                        );
                                                    }}>
                                                        <div
                                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <label
                                                                style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0, width: 260, overflow: "hidden" }}>
                                                                {name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                style={{ cursor: 'pointer' }}
                                                                checked={student_filter_aadon_course === id ? true : false}
                                                                onChange={txt => {
                                                                    fetch_students(
                                                                        route_history, 
                                                                        dispatch, 
                                                                        1, 
                                                                        [], 
                                                                        students_filter, 
                                                                        '', 
                                                                        student_filter_seat, 
                                                                        student_filter_date, 
                                                                        student_filter_course, 
                                                                        id,
                                                                        true, false, student_filter_batch
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </MenuItem>
                                                )
                                            })
                                            : null
                                        }
                                        

                                    </Scrollview>
                                </div>
                            ) : null

                            }

                        </div>
                    </FormControl>
                </div>





                {/* <div style={{ margin: '0px 10px'}}>
                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={students_filter}
                            className={classes.resize}
                            onChange={txt => {
                                // setSort(txt.target.value)
                                fetch_students(route_history, dispatch, 1, [], txt.target.value, '');
                            }}
                        > 
                            <MenuItem value="all">Filter - All Data</MenuItem>

                            {
                                arr_colleges.map((item, key) => {
                                    const { id, name } = item;
                                    return (
                                        <MenuItem key={key} value={id}>{name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>*/}



                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fetch_students(
                                    route_history, 
                                    dispatch, 
                                    1, 
                                    [], 
                                    students_filter, 
                                    keyword, 
                                    student_filter_seat, 
                                    student_filter_date, 
                                    student_filter_course,
                                    student_filter_aadon_course
                                );
                            } else {
                                fetch_students(
                                    route_history, 
                                    dispatch, 
                                    1, 
                                    [], 
                                    students_filter, 
                                    '', 
                                    student_filter_seat, 
                                    student_filter_date, 
                                    student_filter_course,
                                    student_filter_aadon_course
                                );
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
            <AcademicYearModal
                modalStatus={Modal}
                modalAction={setModal}
                YearData={YearData}
                setYearData={setYearData}
            />
        </Navbar>
    )
}

export default NavbarAgency
