class Auth {
    constructor() {
        this.access_token = localStorage.getItem('access_token');
        this.refresh_token = localStorage.getItem('refresh_token');

        if (this.access_token) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    login(access_token, refresh_token, callback) {
        this.set_access_token(access_token);
        this.set_refresh_token(refresh_token);
        this.authenticated = true;
        callback();
    }

    logout(callback) {
        this.authenticated = false;
        localStorage.clear();
        callback();
    }

    isAuthenticated() {
        return this.authenticated;
    }

    getToken() {
        return this.access_token;
    }

    getRefreshToken() {
        return this.refresh_token;
    }

    //For store token locallly. All app fetching these token from here
    set_refresh_token(token) {
        localStorage.setItem('refresh_token', token);
        this.refresh_token = token;
    }

    set_access_token(token) {
        localStorage.setItem('access_token', token);
        this.access_token = token;
    }

}

export default new Auth();