import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { PersonAdd, Print, Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Navbar from '../../../common-components/navbar';
import FeesModal from './fees_modal';
// import { fetch_fees,create_fees, delete_fees } from '../store/action';
import Api from '../../../store/api';
// import { Link, useHistory, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_MAIN, COLLEGE_BASE } from '../../../store/endpoint';
import { selectFeesDatas } from '../store/slice';
import { fetch_fees, print_fees } from '../store/action';
import { CircularProgress } from '@material-ui/core';
// import { selectCollegeDatas } from '../../college/store/slice';


const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));

function NavbarAgency() {
    const classes = useStyles();

    const route_history = useHistory();
    // const dispatch = useDispatch();



    const [Modal, setModal] = useState(false);

    const [College, setCollege] = useState('');
    const [Course, setCourse] = useState('');
    const [Batch, setBatch] = useState('');
    const [Amount, setAmount] = useState('');
    const [Student, setStudent] = useState('');
    const [SearchSuggesstion, setSearchSuggesstion] = useState("");
    const [Description, setDescription] = useState('');
    const [Modeofpay, setModeofpay] = useState('');
    const [Col, setCol] = useState('');

    const [CourseList, setCourseList] = useState([]);
    const [BatchList, setBatchList] = useState([]);
    const [StudentList, setStudentList] = useState([]);
    const [CollegeList, setCollegeList] = useState([]);

    const [PrintButtonSpinner, setPrintButtonSpinner] = useState(false);


    useEffect(() => {
        let api_url_clg = `${COLLEGE_BASE}?page=1&limit=100`;
        const success_clg = (res) => {
            const { response } = res;
            setCollegeList(response);
        }
        Api('get', api_url_clg, '', route_history, success_clg);
    }, [route_history])



    const fetch_courses = (college_id) => {
        let api_url = `${BASE_MAIN}college/course/crud/college/${college_id}/?page=1&limit=200`;
        const success = (res) => {
            const { response } = res;
            console.log('COURSES-RES',res)
            setCourseList(response);
        }
        Api('get', api_url, '', route_history, success);
    }


    const fetch_batches = (course_id) => {
        //let api_url = `${BASE_MAIN}college/course/${course_id}/batch/crud/?page=1&limit=20`
        let api_url = `${BASE_MAIN}college/course/batch/available/?course_id=${course_id}&page=1&limit=200`
        const success = (res) => {
            const { response } = res;
            console.log('BATCHES-RES',res)
            setBatchList(response);
        }
        Api('get', api_url, '', route_history, success);
    }


    const fetch_Studentdata = (batch_id) => {
        let api_url = `${BASE_MAIN}student/batch/${batch_id}/crud/?page=1&limit=1000`;
        const success = (res) => {
            const { statusCode, response } = res;
            console.log('STUDENTS-RES',res)
            if (statusCode === 200) {
                setStudentList(response.records);
            }
        }
        Api('get', api_url, '', route_history, success);
    }



    const dispatch = useDispatch();
    let fees_data = useSelector(selectFeesDatas)
    const { fees_filter, fees_search, fees_filter_from_date, fees_filter_to_date } = fees_data;


    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');


    useEffect(() => {
        setFromDate(fees_filter_from_date);
        setToDate(fees_filter_to_date);
    }, [fees_filter_from_date, fees_filter_to_date])


    const fees_fetching_filters = (search = '', filter = 'all', filter_from_date = '', filter_to_date = '') => {
        fetch_fees(route_history, dispatch, 1, [], search, filter, filter_from_date, filter_to_date);
    }

    const print_btn = () => {
        setPrintButtonSpinner(true);
        const sucess = () => setPrintButtonSpinner(false);
        const failed = () => setPrintButtonSpinner(false);
        print_fees(route_history, fees_filter, fees_filter_from_date, fees_filter_to_date, sucess, failed)
        // fees_filter, date, ToDate
        //print_fees(route_history, filter, filter_from_date, filter_to_date);
        // print_finance(route_history, finance_filter_type, finance_filter_category, FromDate, ToDate, sucess);
    }


    return (
        <Navbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Fees</h4>
            </div>

            <div className="app-nav-right">

                <button
                    style={{ marginRight: 5 }}
                    onClick={() => {
                        setCollege('')
                        setCourse('')
                        setBatch('')
                        setAmount('')
                        setStudent('')
                        setSearchSuggesstion('');
                        setDescription('')
                        setModeofpay('')
                        setCol('')
                        setCourseList([]);
                        setBatchList([]);
                        setStudentList([]);
                        setModal(true);
                    }}
                    className="app-common-btn"
                >
                    <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />
                    Add

                </button>
                {/* <button className="app-common-btn app-btn-green"><Print style={{ fontSize: 16, marginRight: 10 }} />Print</button> */}

                <button
                    onClick={() => print_btn()}
                    style={{ width: 90 }}
                    className={PrintButtonSpinner ? "app-common-btn app-btn-green disabled-btn" : "app-common-btn app-btn-green"}>
                    {
                        PrintButtonSpinner ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <>
                                <Print style={{ fontSize: 16, marginRight: 10 }} />Print
                            </>
                        )
                    }
                </button>

                <div style={{ margin: '0px 10px', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10 }}>From</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (ToDate && date) {
                                //date api call
                                fees_fetching_filters(fees_search, fees_filter, date, ToDate);
                            } else {
                                setFromDate(date);
                            }
                        }}

                    />

                    <p style={{ marginLeft: 20, marginRight: 10 }}>To</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (FromDate && date) {
                                //date api call
                                fees_fetching_filters(fees_search, fees_filter, FromDate, date);
                            } else {
                                setToDate(date);
                            }
                        }}
                    />

                </div>

                <div style={{ margin: '0px 10px' }}>

                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={fees_filter}
                            className={classes.resize}
                            onChange={txt => {
                                //setSort(txt.target.value)
                                fees_fetching_filters(fees_search, txt.target.value, fees_filter_from_date, fees_filter_to_date);
                            }}
                        >
                            <MenuItem value={'all'}>Filter - All</MenuItem>
                            {
                                // arr_colleges.map((item, key) => {
                                //     const { id, name } = item
                                //     return (
                                //         <MenuItem key={key} value={id}>{name}</MenuItem>
                                //     )
                                // })
                                CollegeList && CollegeList.map((item, key) => {
                                    const { id, name } = item;
                                    return (
                                        <MenuItem key={key} value={id}>{name}</MenuItem>
                                    )
                                })

                            }
                        </Select>
                    </FormControl>
                </div>




                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fees_fetching_filters(keyword, fees_filter, fees_filter_from_date, fees_filter_to_date);
                            } else {
                                fees_fetching_filters('', fees_filter, fees_filter_from_date, fees_filter_to_date);
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
            <FeesModal
                modalStatus={Modal}
                modalAction={setModal}

                fetch_courses={fetch_courses}
                fetch_batches={fetch_batches}
                fetch_Studentdata={fetch_Studentdata}



                CollegeList={CollegeList}
                setCollegeList={setCollegeList}

                CourseList={CourseList}
                // setCourseList={setCourseList}

                BatchList={BatchList}
                // setBatchList={setBatchList}

                StudentList={StudentList}
                // setStudentList={setStudentList}


                College={College}
                setCollege={setCollege}


                Course={Course}
                setCourse={setCourse}

                Batch={Batch}
                setBatch={setBatch}


                Student={Student}
                setStudent={setStudent}

                search={SearchSuggesstion}
                setSearch={setSearchSuggesstion}

                Amount={Amount}
                setAmount={setAmount}

                Description={Description}
                setDescription={setDescription}

                // CollectionPlace={CollectionPlace}
                // setCollectionPlace={setCollectionPlace}

                Modeofpay={Modeofpay}
                setModeofpay={setModeofpay}

                Col={Col}
                setCol={setCol}


            />
        </Navbar>

    )
}

export default NavbarAgency
