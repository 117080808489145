import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { selectCollegeDatas, update_addon_course } from '../store/slice';
import { useHistory } from 'react-router-dom';
import { create_college_course, update_college_course } from '../store/action';
import { Cancel } from '@material-ui/icons';
import Scrollbars from 'react-custom-scrollbars';
import { create_clg_addon_course, delete_clg_addon_course } from '../store/action';
import { CircularProgress } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
            zIndex: '1 !important'
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function CourseModal({
    modalStatus,
    modalAction,
    data,
    College,
    setCollege,
    Name,
    setName,
    GraduationType,
    setGraduationType,
    DurationType,
    setDurationType,
    Duration,
    setDuration

}) {

    const classes = useStyles();
    const router_history = useHistory();
    const dispatch = useDispatch();

    const { arr_colleges, arr_addon_course } = useSelector(selectCollegeDatas);
    const [AdonCourse, setAdonCourse] = useState('')
    const [buttonLoader, setButtonLoader] = useState(false);


    const handleClose = () => {
        modalAction(false)
        setButtonLoader(false);
        setName('')
        setGraduationType('')
        setDurationType('')
        setDuration('')
        dispatch(update_addon_course({
            arr_response: [],
        }))
    };

    let name = '';
    let graduation_type = '';
    let duration_type = '';
    let duration = '';
    let college = '';

    if (data) {
        name = data.name || '';
        graduation_type = data.graduation_type || '';
        duration_type = data.duration_type || '';
        duration = data.duration || '';
        college = data.college || '';
    }

    const button_fx = () => {
        setButtonLoader(true);

        let postData = {
            name: Name,
            graduation_type: GraduationType,
            duration_type: DurationType,
            duration: Duration,
            college: College
        }

        const success = () => {
            setButtonLoader(false);
            // setName('')
            // setGraduationType('')
            // setDurationType('')
            // setDuration('')
            // setCollege('')
            handleClose()
        }
        const failed = () =>{
            setButtonLoader(false)
        }

        if (data) {
            // console.log('redy for update api call', postData)
            update_college_course(router_history, dispatch, postData, data.id, success, failed)
        } else {
            // console.log('redy for create api call', postData)
            create_college_course(router_history, dispatch, postData, success, failed)
        }

    }

    const delete_fx = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_clg_addon_course(router_history, dispatch, id);
            }
        })
    }

    const addAddonCourse = () => {

        const success = () => setAdonCourse('');
        const failed = () => setAdonCourse('');

        let postData = {
            course: data.id,
            name: AdonCourse
        }

        create_clg_addon_course(router_history, dispatch, postData, success,failed);

    }

    let add_btn_status = false;
    if (Name && GraduationType && DurationType && Duration && College) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (Name !== name || GraduationType !== graduation_type || DurationType !== duration_type || Duration !== duration || College !== college) {
        update_btn_status = true
    }

    let general_button = false;
    data ? general_button = update_btn_status : general_button = add_btn_status

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div style={{ width: data ? '60vw' : '' }} className="app-modal-main">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '30vw' }}>
                        <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Create'} Course</h4>
                        <form className={classes.root} noValidate autoComplete="off">

                            <TextField
                                disabled
                                id="standard-basic"
                                select
                                label="College"
                                style={{ width: '30vw' }}
                                value={College}
                                onChange={txt => setCollege(txt.target.value)}
                            >
                                <MenuItem value={''}>Select College</MenuItem>
                                {
                                    arr_colleges.map((item, key) => {
                                        const { id, name } = item;
                                        return (
                                            <MenuItem key={key} value={id}>{name}</MenuItem>
                                        )
                                    })
                                }

                            </TextField>

                            <TextField
                                id="standard-basic"
                                label="Course Name"
                                style={{ width: '30vw' }}
                                value={Name}
                                onChange={txt => setName(txt.target.value)}
                            />

                            <TextField
                                id="standard-basic"
                                select
                                label="Graduation Type"
                                style={{ width: '30vw' }}
                                value={GraduationType}
                                onChange={txt => setGraduationType(txt.target.value)}
                            >
                                <MenuItem value={''}>Graduation Type</MenuItem>
                                <MenuItem value={'ug'}>Under Graduation</MenuItem>
                                <MenuItem value={'pg'}>Post Graduation</MenuItem>
                                <MenuItem value={'bpham'}>BPham</MenuItem>
                                <MenuItem value={'diploma'}>Diploma</MenuItem>
                                <MenuItem value={'dpham'}>Dpham</MenuItem>

                            </TextField>

                            <TextField
                                id="standard-basic"
                                select
                                label="Duration Type"
                                style={{ width: '30vw' }}
                                value={DurationType}
                                onChange={txt => setDurationType(txt.target.value)}
                            >
                                <MenuItem value={''}>Duration Type</MenuItem>
                                <MenuItem value={'year'}>Year</MenuItem>
                                <MenuItem value={'semester'}>Semester</MenuItem>

                            </TextField>

                            <TextField
                                id="standard-basic"
                                select
                                label="Duration"
                                style={{ width: '30vw' }}
                                value={Duration}
                                onChange={txt => setDuration(txt.target.value)}
                            >
                                <MenuItem value={''}>Duration</MenuItem>
                                <MenuItem value={'1'}>1</MenuItem>
                                <MenuItem value={'2'}>2</MenuItem>
                                <MenuItem value={'3'}>3</MenuItem>
                                <MenuItem value={'4'}>4</MenuItem>
                                <MenuItem value={'5'}>5</MenuItem>
                                <MenuItem value={'6'}>6</MenuItem>
                                <MenuItem value={'7'}>7</MenuItem>
                                <MenuItem value={'8'}>8</MenuItem>

                            </TextField>
                        </form>
                    </div>

                    {
                        data && (
                            <div style={{ width: '26vw', borderRadius: 20, backgroundColor: '#cdcdcd', padding: 10 }}>
                                <p style={{ fontSize: 18, fontWeight: 'bold' }}>Ad-On Courses</p>

                                <div style={{ display: 'flex', backgroundColor: '#fff', borderRadius: 10, padding: '10px 5px', margin: '10px 0px' }}>
                                    <input
                                        type="text"
                                        placeholder="course name"
                                        style={{ width: '100%', border: '0px' }}
                                        value={AdonCourse}
                                        onChange={txt => setAdonCourse(txt.target.value)}
                                    />
                                    {
                                        AdonCourse ? (
                                            <button onClick={() => addAddonCourse()} style={{ height: 30, marginRight: 0 }} className="app-common-btn">Add</button>
                                        ) : (
                                            <button style={{ height: 30, marginRight: 0 }} className="app-common-btn disabled-btn">Add</button>
                                        )
                                    }

                                </div>

                                <Scrollbars style={{ height: '30vh' }}>
                                    {
                                        arr_addon_course.map((item, key) => {
                                            const { id, name } = item;
                                            return (
                                                <div key={key} style={{ display: 'flex', alignItems: 'center', fontSize: 14, margin: 10 }}>
                                                    <p style={{ flex: 0.5 }}>{key + 1}.</p>
                                                    <p style={{ flex: 4 }}>{name}</p>
                                                    {/* <Edit style={{ fontSize: 15, marginRight: 20 }} /> */}
                                                    <Cancel onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20 }} />
                                                </div>
                                            )
                                        })
                                    }
                                </Scrollbars>
                            </div>
                        )
                    }

                </div>



                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default CourseModal
