import React from 'react';
import { Search } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Navbar from '../../../common-components/navbar';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectcommissionDatas } from '../store/slice';
import { fetch_commission } from '../store/action';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black',
    }
}));

function NavbarAgency() {
    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();
    let commission_data = useSelector(selectcommissionDatas)
    const { commission_search, commission_type } = commission_data;

    return (
        <Navbar>
            <h4>Commission</h4>
            <div className="app-nav-right">
                {/* <button 
                    onClick={() => modalStatus(true)}
                    className="app-common-btn">
                        <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                </button>
                */}
                <div style={{ margin: '0px 10px' }}>

                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={commission_type}
                            className={classes.resize}
                            onChange={txt => {
                                //setSort(txt.target.value)
                                fetch_commission(route_history, dispatch, 1, [], txt.target.value, commission_search,);
                            }}
                        >
                            <MenuItem value={'all'}>Filter - All</MenuItem>
                            <MenuItem value={'updated'}>Updated</MenuItem>
                            <MenuItem value={'non-updated'}>Not Updated</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fetch_commission(route_history, dispatch, 1, [], commission_type, keyword);
                            } else {
                                fetch_commission(route_history, dispatch, 1, [], commission_type, '');
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
        </Navbar>
    )
}

export default NavbarAgency
