import React, { useState } from 'react'
import { 
    //Edit,
     PersonAdd, Search } from '@material-ui/icons'
import Navbar from '../../../common-components/navbar';
import CollegeModal from '../components/college_modal';
import { 
    //delete_academic_year,
     fetch_colleges } from '../store/action';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectPermissionDatas } from '../../../common-components/store/slice';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectCollegeDatas } from '../store/slice';
import AcademicYearModal from '../../students/components/add_academic_year_modal';
// import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));


function NavbarAgency() {

    const route_history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { current_user_type } = useSelector(selectPermissionDatas);
    const { college_acd_yr_list, college_acd_yr } = useSelector(selectCollegeDatas) 


    const [Modal, setModal] = useState(false);
    const [YrModal, setYrModal] = useState(false);
    const [Name, setName] = useState('');
    const [CordinatorName, setCordinatorName] = useState('');
    const [CordinatorPhone, setCordinatorPhone] = useState('');
    const [CordinatorEmail, setCordinatorEmail] = useState('');
    const [EmailError, setEmailError] = useState('');

    const [openStatus, setOpenStatus] = useState(false)

    // const delete_fx = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             delete_academic_year(route_history, dispatch, id);
    //         }
    //     })
    // }

    return (
        <Navbar>
            <h4>College{college_acd_yr && college_acd_yr_list ? `(${college_acd_yr_list.find(el => el.id===college_acd_yr).year})` : ''}</h4>
            <div className="app-nav-right">
                <div style={{ margin: '0px 10px' }}>

                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            open={openStatus}
                            value={
                                college_acd_yr ? college_acd_yr : 'all'
                            }
                            className={classes.resize}
                            onClick={()=> setOpenStatus(!openStatus) }
                            onChange={txt => {
                                let filterVal = null
                                console.log(txt.target.value)
                                if(txt.target.value!=='all')
                                    filterVal = txt.target.value
                                else 
                                    filterVal = null
                                fetch_colleges(route_history, dispatch, 1, [], '', 20, true, filterVal  )

                                setOpenStatus(false)
                            }}
                        >
                            <MenuItem value={'all'}>Filter - All</MenuItem>
                            {
                                
                                college_acd_yr_list && college_acd_yr_list.map((item, key) => {
                                    const { id, year } = item;
                                return (
                                    <MenuItem key={key} value={id} style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between' }}>
                                        <span>{year}</span>
                                        <span name='acdYrEditOpts' onClick={(e)=>{
                                                setOpenStatus(false)
                                                e.stopPropagation(); }}>
                                            {/* <Edit onClick={()=> { 
                                                setyeardata(item); setYearData(year); setYrModal(true);
                                                
                                                }}  style={{ fontSize: 16, marginRight: 10 }}/> */}
                                            {/* <Delete onClick={() => delete_fx(id) } style={{ fontSize: 16, marginRight: 10 }} /> */}
                                        </span>
                                    </MenuItem>
                                )
                                })

                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    current_user_type === 'superadmin' || current_user_type === 'admin' ? (
                        <button
                            onClick={() => {
                                setName('');
                                setCordinatorName('');
                                setCordinatorPhone('');
                                setCordinatorEmail('');
                                setEmailError('');
                                setModal(true)
                            }}
                            className="app-common-btn">
                            <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                        </button>
                    ) : (null)
                }

                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                fetch_colleges(route_history, dispatch, 1, [], keyword, 20, true, college_acd_yr);
                            } else {
                                fetch_colleges(route_history, dispatch, 1, [], '', 20, true, college_acd_yr);
                            }
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />
                </div>

            </div>
            <CollegeModal
                modalStatus={Modal}
                modalAction={setModal}
                Name={Name}
                setName={setName}
                CordinatorName={CordinatorName}
                setCordinatorName={setCordinatorName}
                CordinatorPhone={CordinatorPhone}
                setCordinatorPhone={setCordinatorPhone}
                CordinatorEmail={CordinatorEmail}
                setCordinatorEmail={setCordinatorEmail}
                EmailError={EmailError}
                setEmailError={setEmailError}
            />
            <AcademicYearModal
                modalStatus={YrModal}
                modalAction={setYrModal}
            />
        </Navbar>
    )
}

export default NavbarAgency
