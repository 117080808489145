import { createSlice } from '@reduxjs/toolkit';

export const studentSlice = createSlice({
    name: 'students',
    initialState: {
        arr_students: [],
        total_students: 0,
        direct_students: 0,
        agency_students: 0,
        students_page: 1,
        students_search: '',
        students_pagination: true,
        students_filter: 'all',
        student_filter_date: '',
        student_filter_seat: 'all',
        student_filter_course: 'all',
        student_filter_aadon_course: 'all',
        student_filter_batch: 'all',
        students_loader: false,
        students_listbottom_loader: false,

        arr_all_course_list: [],
        arr_all_addoncourse_list: [],

        arr_students_fees: [],
        student_due_loader: false,
        arr_student_fee_general_info: {
            current_due: 0,
            current_duration: "",
            paid_amount: 0,
            total: 0,
            total_due: 0
        },

    },
    reducers: {
        update_staudents: (state, action) => {
            const { arr_response, total_students, direct_students, agency_students, page_no, pagination, search, filter, date, seat_type, course, addonCourse, batch } = action.payload;
            state.arr_students = arr_response;
            state.total_students = total_students;
            state.direct_students = direct_students;
            state.agency_students = agency_students;
            state.students_page = page_no;
            state.students_search = search;
            state.students_pagination = pagination;
            state.students_filter = filter;
            state.student_filter_date = date;
            state.student_filter_seat = seat_type;
            state.student_filter_course = course;
            state.student_filter_aadon_course = addonCourse;
            state.student_filter_batch = batch;
            state.students_loader = false;
            state.students_listbottom_loader = false;
        },

        update_students_loader: (state, action) => {
            const { loader } = action.payload;
            state.students_loader = loader;
        },

        update_students_listbottom_loader: (state, action) => {
            const { loader } = action.payload;
            state.students_listbottom_loader = loader;
        },

        update_all_courses: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_all_course_list = arr_response;
        },

        update_all_addon_courses: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_all_addoncourse_list = arr_response;
        },

        add_single_student: (state, action) => {
            const { new_data } = action.payload;
            state.arr_students.unshift(new_data);
        },

        remove_single_student: (state, action) => {
            const { student_id } = action.payload;
            let latest_list = state.arr_students.filter(item => item.id !== student_id);
            state.arr_students = latest_list;
        },

        update_single_student: (state, action) => {
            const { student_id, new_data } = action.payload;
            let array_item = state.arr_students.find(item => item.id === student_id);
            let arra_item_index = state.arr_students.indexOf(array_item);
            state.arr_students[arra_item_index] = new_data;
        },


        // FEE DUE ACTIONS
        update_student_due_fees: (state, action) => {
            const { arr_response, general_info } = action.payload;
            state.arr_students_fees = arr_response;
            state.arr_student_fee_general_info = general_info;
            state.student_due_loader = false;
        },

        update_students_due_loader: (state, action) => {
            const { loader } = action.payload;
            state.student_due_loader = loader;
        },

    }
})

export const {
    update_staudents,
    update_students_loader,
    update_students_listbottom_loader,
    add_single_student,
    remove_single_student,
    update_single_student,

    update_all_courses,
    update_all_addon_courses,

    update_student_due_fees,
    update_students_due_loader,


} = studentSlice.actions

export const selectStudentsDatas = state => state.student;

export default studentSlice.reducer;