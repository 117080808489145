import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        agency : true,
        agency_detail : true,
        college : true,
        commission : true,
        fees : true,
        finanace : true,
        staff : true,
        student : true,
        current_user_type: '',
    },
    reducers: {
        update_permission: (state, action) => {
            const { agency, agency_detail, college, commission, fees, finanace, staff, student, current_user_type } = action.payload;        
            state.agency = agency;
            state.agency_detail = agency_detail;
            state.college = college;
            state.commission = commission;
            state.fees = fees;
            state.finanace = finanace;
            state.staff = staff;
            state.student = student;
            state.current_user_type = current_user_type;
        }
    }
})

export const { update_permission } = permissionSlice.actions
export const selectPermissionDatas = state => state.permission;

export default permissionSlice.reducer;