import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PersonAdd, Print, Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Navbar from '../../../common-components/navbar';
import Scrollview from 'react-custom-scrollbars';
import FilterListIcon from '@material-ui/icons/FilterList';
import { selectFinanceDatas } from '../store/slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetch_finance, print_finance } from '../store/action';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));


function NavbarAgency({
    setSelectedFinance,
    modalStatus,
    setAmount,
    setDescription,
    setType,
    setCategory,
    setModePay,
    setSubCategory,
    setAgencyId
}) {
    const classes = useStyles();

    // const [sort, setSort] = useState('all')

    const [isOpen, setIsOpen] = useState(false);
    const [isFYrOpen, setIsFYrOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const handleFYrClick = () => setIsFYrOpen(!isFYrOpen);
    const dropdownRef = useRef(undefined);
    const dropdownFYrRef = useRef(undefined);
    const buttonRef = useRef(undefined);
    const buttonFYrRef = useRef(undefined);

    const route_history = useHistory();
    const dispatch = useDispatch();
    const { 
        arr_category,
        arr_sub_category, 
        financialYrList,
        finance_search, 
        finance_filter_type, 
        finance_filter_category,
        finance_filter_subcategory, 
        finance_filter_from_date, 
        finance_filter_to_date,
        finance_filter_year 
    } = useSelector(selectFinanceDatas);

    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [PrintButtonSpinner, setPrintButtonSpinner] = useState(false);
    // const [Income, setIncome] = useState('');
    // const [Expense, setExpense] = useState('');

    useEffect(() => {
        setFromDate(finance_filter_from_date);
        setToDate(finance_filter_to_date);
    }, [finance_filter_from_date, finance_filter_to_date])

    useEffect(() => {

        const handleClickOutside = event => {
            const isDropdownClick =
                dropdownRef.current && dropdownRef.current.contains(event.target);
            const isButtonClick =
                buttonRef.current && buttonRef.current.contains(event.target);
            const isFYrDropDownCLick = 
                dropdownFYrRef.current && dropdownFYrRef.current.contains(event.target);
            const isFYRButtonClick = 
                buttonFYrRef.current && buttonFYrRef.current.contains(event.target);

            if (isDropdownClick || isButtonClick || isFYrDropDownCLick || isFYRButtonClick) {
                // If the ref is not defined or the user clicked on the menu, we don’t do anything.
                return;
            }
            // Otherwise we close the menu.
            setIsOpen(false);
            setIsFYrOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dropdownRef, buttonRef])

    const finance_fetching_filters = (search = '', filter_type = 'all', filter_category = 'all', filter_subcategory = 'all', filter_from = '', filter_to = '', financeYr = null) => {
        fetch_finance(route_history, dispatch, 1, [], search, filter_type, filter_category, filter_subcategory, filter_from, filter_to, true, false, financeYr==='all' ? null : financeYr===null ? finance_filter_year : financeYr);
    }
    

    const print_btn = () => {
        setPrintButtonSpinner(true);
        const sucess = () => setPrintButtonSpinner(false);
        const failed = () => setPrintButtonSpinner(false);
        print_finance(route_history, finance_filter_type, finance_filter_category, FromDate, ToDate, sucess, failed);
    }


    return (
        <Navbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Finance{financialYrList && finance_filter_year ? `(${financialYrList.find(el => el.id===finance_filter_year).year})` : '' }</h4>
            </div>

            <div className="app-nav-right">
                <button
                    onClick={() => {
                        setSelectedFinance('')
                        setAmount('')
                        setDescription('')
                        setType('')
                        setCategory('')
                        setModePay('')
                        setSubCategory('')
                        setAgencyId('')
                        modalStatus(true)
                    }}
                    style={{ marginRight: 5 }}
                    className="app-common-btn">
                    <PersonAdd style={{ fontSize: 16, marginRight: 10 }} />Add
                </button>

                <button
                    onClick={() => print_btn()}
                    style={{ width: 90 }}
                    className={PrintButtonSpinner ? "app-common-btn app-btn-green disabled-btn" : "app-common-btn app-btn-green"}>
                    {
                        PrintButtonSpinner ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <>
                                <Print style={{ fontSize: 16, marginRight: 10 }} />Print
                            </>
                        )
                    }
                </button>

                <div style={{ margin: '0px 10px', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10 }}>From</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (ToDate && date) {
                                //date api call
                                finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, finance_filter_subcategory, date, ToDate);
                            } else {
                                setFromDate(date);
                            }
                        }}
                    />

                    <p style={{ marginLeft: 20, marginRight: 10 }}>To</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (FromDate && date) {
                                //date api call
                                finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, finance_filter_subcategory, FromDate, date);
                            } else {
                                setToDate(date);
                            }
                        }}
                    />
                </div>

                <div style={{ margin: '0px 10px' }}>
                    <FormControl className={classes.formControl}>
                        <div className="content_filter_student" onClick={handleClick} ref={buttonRef} >
                            <FilterListIcon style={{ color: 'white', cursor: 'pointer' }} />
                            <p style={{ color: 'white', fontSize: '13px', fontWeight: '480', cursor: 'pointer' }}>Filter</p>

                            {isOpen ? (
                                <div className="filter_layout" onClick={(e) => { e.stopPropagation(); }} ref={dropdownRef}>
                                    <Scrollview>
                                        <MenuItem value="">
                                            <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}>Income </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={finance_filter_type === 'all' || finance_filter_type === 'Income' ? true : false}
                                                onChange={txt => {

                                                    let this_is = 'Income';
                                                    let other_is = 'Expense';
                                                    let current_check = false;
                                                    let other_check = false;
                                                    let selection = 'all';
                                                    if (finance_filter_type === 'all') {
                                                        current_check = true;
                                                        other_check = true;
                                                    } else if (finance_filter_type === this_is) current_check = true;
                                                    else if (finance_filter_type === other_is) other_check = true;

                                                    if (current_check && other_check) {
                                                        selection = other_is;
                                                    } else if (current_check && other_check === false) {
                                                        selection = null;
                                                    } else if (current_check === false && other_check) {
                                                        selection = 'all';
                                                    }
                                                    if (selection) {
                                                        finance_fetching_filters(finance_search, selection, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                                                        // setIsOpen(false);
                                                    }

                                                }}
                                            />
                                        </MenuItem>
                                        <MenuItem  value="">
                                            <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}>Expense </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={finance_filter_type === 'all' || finance_filter_type === 'Expense' ? true : false}
                                                onChange={txt => {
                                                    let this_is = 'Expense';
                                                    let other_is = 'Income';
                                                    let current_check = false;
                                                    let other_check = false;
                                                    let selection = 'all';
                                                    if (finance_filter_type === 'all') {
                                                        current_check = true;
                                                        other_check = true;
                                                    } else if (finance_filter_type === this_is) current_check = true;
                                                    else if (finance_filter_type === other_is) other_check = true;

                                                    if (current_check && other_check) {
                                                        selection = other_is;
                                                    } else if (current_check && other_check === false) {
                                                        selection = null;
                                                    } else if (current_check === false && other_check) {
                                                        selection = 'all';
                                                    }
                                                    if (selection) {
                                                        finance_fetching_filters(finance_search, selection, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                                                        // setIsOpen(false);
                                                    }
                                                }}
                                            />
                                        </MenuItem>


                                        <p style={{ fontSize: '12px', color: 'grey', paddingLeft: '10px' }}>
                                            Category
                                        </p>
                                        <MenuItem value="">
                                            <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}> All - Filter </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={finance_filter_category === 'all' ? true : false}
                                                onChange={() => {
                                                    finance_fetching_filters(finance_search, finance_filter_type, 'all', finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                                                    // setIsOpen(false);             
                                                }}
                                            />
                                        </MenuItem>
                                        {
                                            arr_category.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} value="">
                                                        <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}> {name} </label>
                                                        <input
                                                            type="checkbox"
                                                            style={{ cursor: 'pointer' }}
                                                            checked={finance_filter_category === id ? true : false}
                                                            onChange={() => {
                                                                finance_fetching_filters(finance_search, finance_filter_type, id, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                                                                // setIsOpen(false);    
                                                            }}
                                                        />
                                                    </MenuItem>
                                                )
                                            })
                                        }


                                        <p style={{ fontSize: '12px', color: 'grey', paddingLeft: '10px' }}>
                                            Sub Category
                                        </p>
                                        <MenuItem value="">
                                            <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}> All - Filter </label>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={finance_filter_subcategory === 'all' ? true : false}
                                                onChange={() => {
                                                    finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, 'all', finance_filter_from_date, finance_filter_to_date);
                                                    // setIsOpen(false);             
                                                }}
                                            />
                                        </MenuItem>
                                        {
                                            arr_sub_category.map((item, key) => {
                                                const { id, name } = item;
                                                return (
                                                    <MenuItem key={key} value="">
                                                        <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}> {name} </label>
                                                        <input
                                                            type="checkbox"
                                                            style={{ cursor: 'pointer' }}
                                                            checked={finance_filter_subcategory === id ? true : false}
                                                            onChange={() => {
                                                                finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, id, finance_filter_from_date, finance_filter_to_date);
                                                                // setIsOpen(false);    
                                                            }}
                                                        />
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Scrollview>
                                </div>
                            ) : null

                            }

                        </div>
                    </FormControl>
                </div>

                <div style={{ margin: '0px' }}>
                    <FormControl className={classes.formControl}>
                        <div className="content_filter_student" style={{ width : 'auto' }} onClick={handleFYrClick} ref={buttonFYrRef} >
                            <FilterListIcon style={{ color: 'white', cursor: 'pointer' }} />
                            <p style={{ color: 'white', fontSize: '13px', fontWeight: '480', cursor: 'pointer' }}>Filter by Financial Year</p>

                            {isFYrOpen ? (
                                <div className="filter_layout" onClick={(e) => { e.stopPropagation(); }} ref={dropdownFYrRef}>
                                    <Scrollview>
                                        <MenuItem value="">
                                            <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}>All</label>
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer' }}
                                                    checked={ finance_filter_year===null ? true : false}
                                                    onChange={() => {
                                                        setIsFYrOpen(false)
                                                        finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date, 'all');
                                                        // setIsOpen(false);    
                                                    }}
                                                />
                                        </MenuItem>
                                        {
                                            financialYrList ?
                                            financialYrList.map((item, key) => {
                                                const { id, year } = item;
                                                return (
                                                    <MenuItem key={key} value="">
                                                        <label style={{ fontSize: '16px', fontWeight: 'bold', width: '55vw', padding: '15px' }}> {year}</label>
                                                        <input
                                                            type="checkbox"
                                                            style={{ cursor: 'pointer' }}
                                                            checked={ finance_filter_year ? finance_filter_year === id ? true : false : false}
                                                            onChange={() => {
                                                                setIsFYrOpen(false)
                                                                finance_fetching_filters(finance_search, finance_filter_type, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date, item.id);
                                                                // setIsOpen(false);    
                                                            }}
                                                        />
                                                    </MenuItem>
                                                )
                                            })
                                            : <MenuItem>No Years are added</MenuItem>
                                        }
                                    </Scrollview>
                                </div>
                            ) : null

                            }

                        </div>
                    </FormControl>
                </div>


                <div className="app-nav-searchbar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={txt => {
                            let keyword = txt.target.value;
                            if (keyword) {
                                finance_fetching_filters(keyword, finance_filter_type, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                            } else {
                                finance_fetching_filters('', finance_filter_type, finance_filter_category, finance_filter_subcategory, finance_filter_from_date, finance_filter_to_date);
                            }
                        }}

                    />
                    <Search style={{ fontSize: 18 }} />

                </div>

            </div>
        </Navbar>
    )
}

export default NavbarAgency
