import React, { useEffect, useState } from 'react';
import NavbarAgency from './components/navbar';
import ListAgency from './components/list';
import StaffModal from './components/staff_modal';
import { fetch_staffs } from './store/action';
import { fetch_colleges } from '../college/store/action';
import { useDispatch } from 'react-redux';

function App(props) {
    const [Modal, setModal] = useState(false);
    const [Name, setName] = useState('');
    const [Designation, setDesignation] = useState('');
    const [PhoneNo, setPhoneNo] = useState('');
    const [Email, setEmail] = useState('');
    const [College, setCollege] = useState('');
    const [Password, setPassword] = useState('');
    const [EmailError, setEmailError] = useState('');

    let route_history = props.history
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_staffs(route_history, dispatch, 1, []);
        fetch_colleges(route_history, dispatch, 1, [], '', 60);
    }, [route_history, dispatch])

    return (
        <div className="app-dynamic-layout">
            <NavbarAgency modalStatus={setModal} />
            <ListAgency modalStatus={setModal} />
            <StaffModal
                modalStatus={Modal}
                modalAction={setModal}
                Name={Name}
                setName={setName}
                Designation={Designation}
                setDesignation={setDesignation}
                PhoneNo={PhoneNo}
                setPhoneNo={setPhoneNo}
                Email={Email}
                College={College}
                setCollege={setCollege}
                setEmail={setEmail}
                Password={Password}
                setPassword={setPassword}
                EmailError={EmailError}
                setEmailError={setEmailError}
            />
        </div>
    )
}

export default App
