import { createSlice } from '@reduxjs/toolkit';

export const financeSlice = createSlice({
    name: 'finance',
    initialState: {

        arr_finance: [],
        arr_category: [],
        financialYrList: [],
        arr_sub_category: [],
        finance_page: 1,
        finance_pagination: true,
        finance_search: '',
        finance_filter_type: 'all',
        finance_filter_category: 'all',
        finance_filter_subcategory: 'all',
        finance_filter_from_date: '',
        finance_filter_to_date: '',
        finance_filter_year: null,
        total_expense: 0,
        total_income: 0,
        finance_loader: false,
        finance_list_loader: false,
        finance_category_loader: false,
        finance_sub_category_loader: false,
        finance_details: {
            total_income: 0,
            total_expense: 0,
        }


    },
    reducers: {
        // Agency informations
        update_finances: (state, action) => {
            const { arr_response, total_expense, total_income, page_no, pagination, search, filter_type, filter_category, filter_subcategory, filter_from, filter_to, finance_filter_year } = action.payload;
            state.arr_finance = arr_response;
            state.finance_page = page_no;
            state.finance_pagination = pagination;
            state.finance_search = search;
            state.finance_filter_type = filter_type;
            state.finance_filter_category = filter_category;
            state.finance_filter_subcategory = filter_subcategory;
            state.finance_filter_from_date = filter_from;
            state.finance_filter_to_date = filter_to;
            state.total_expense = total_expense;
            state.total_income = total_income;
            state.finance_loader = false;
            state.finance_list_loader = false;
            state.finance_filter_year = finance_filter_year
        },
        update_finances_loader: (state, action) => {
            const { loader } = action.payload;
            state.finance_loader = loader;
        },
        update_finances_list_loader: (state, action) => {
            const { loader } = action.payload;
            state.finance_list_loader = loader;
        },
        update_finances_details: (state, action) => {
            const { obj_response } = action.payload;
            state.finance_details = obj_response;
        },
        add_single_finance: (state, action) => {
            const { new_data } = action.payload;
            state.arr_finance.unshift(new_data);
            let amount = new_data.amount;
            let type = new_data.finance_type;
            if (type === 'Income') {
                let income = Number(state.finance_details.total_income) + Number(amount);
                state.finance_details.total_income = income;
            }
            else if (type === 'Expense') {
                let expense = Number(state.finance_details.total_expense) + Number(amount);
                state.finance_details.total_expense = expense;
            }



        },
        remove_single_finance: (state, action) => {
            const { finance_id } = action.payload;
            let latest_list = state.arr_finance.filter(item => item.id !== finance_id);
            state.arr_finance = latest_list;
        },
        update_single_finance: (state, action) => {
            const { finance_id, new_data } = action.payload;
            let array_item = state.arr_finance.find(item => item.id === finance_id);
            let arra_item_index = state.arr_finance.indexOf(array_item);
            state.arr_finance[arra_item_index] = new_data;
        },


        update_category: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_category = arr_response;
            state.finance_category_loader = false;
        },
        update_finance_category_loader: (state, action) => {
            const { loader } = action.payload;
            state.finance_category_loader = loader;
        },
        add_single_category: (state, action) => {
            const { new_data } = action.payload;
            state.arr_category.unshift(new_data);
        },
        update_single_category: (state, action) => {
            const { category_id, new_data } = action.payload;
            let array_item = state.arr_category.find(item => item.id === category_id);
            let arra_item_index = state.arr_category.indexOf(array_item);
            state.arr_category[arra_item_index] = new_data;
        },
        remove_single_category: (state, action) => {
            const { category_id } = action.payload;
            let latest_list = state.arr_category.filter(item => item.id !== category_id);
            state.arr_category = latest_list;
        },



        update_sub_category: (state, action) => {
            const { arr_response } = action.payload;
            state.arr_sub_category = arr_response;
            state.finance_sub_category_loader = false;
        },
        update_sub_finance_category_loader: (state, action) => {
            const { loader } = action.payload;
            state.finance_sub_category_loader = loader;
        },
        add_single_sub_category: (state, action) => {
            const { new_data } = action.payload;
            state.arr_sub_category.unshift(new_data);
        },
        update_single_sub_category: (state, action) => {
            const { category_id, new_data } = action.payload;
            let array_item = state.arr_sub_category.find(item => item.id === category_id);
            let arra_item_index = state.arr_sub_category.indexOf(array_item);
            state.arr_sub_category[arra_item_index] = new_data;
        },
        remove_single_sub_category: (state, action) => {
            const { category_id } = action.payload;
            let latest_list = state.arr_sub_category.filter(item => item.id !== category_id);
            state.arr_sub_category = latest_list;
        },

        create_financial_yr: (state, action) => {
            const { financial_yr_list } = action.payload;
            state.financialYrList = financial_yr_list;
        },
        update_financial_yr: (state, action) => {
            const { financial_yr, id } = action.payload;
            let arr_item = state.arr_finance.find(item => item.id === id)
            let arr_idx = state.arr_finance.indexOf(arr_item)
            state.arr_finance[arr_idx] = financial_yr
        },
        add_financial_yr: (state, action) => {
            const { new_data } = action.payload;
            state.financialYrList.unshift(new_data);
        },
        remove_financial_yr: (state, action) => {
            const { financial_yr_id } = action.payload;
            let latest_list = state.financialYrList.filter(item => item.id !== financial_yr_id);
            state.financialYrList = latest_list;
        }
    }
})

export const {
    update_finances,
    update_finances_loader,
    update_finances_list_loader,
    update_finances_details,
    update_category,
    update_finance_category_loader,
    add_single_finance,
    remove_single_finance,
    update_single_finance,
    add_single_category,
    update_single_category,
    remove_single_category,

    update_sub_category,
    update_sub_finance_category_loader,
    add_single_sub_category,
    update_single_sub_category,
    remove_single_sub_category,

    create_financial_yr,
    update_financial_yr,
    add_financial_yr,
    remove_financial_yr

} = financeSlice.actions

export const selectFinanceDatas = state => state.finance;

export default financeSlice.reducer;