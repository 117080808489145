import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from './routes';
import { useSelector } from 'react-redux'
import { selectPermissionDatas } from '../common-components/store/slice'

function App() {
    const permissions = useSelector(selectPermissionDatas);
    const { agency, staff, college, student, fees, commission, finanace } = permissions

    let redirect_path = '/'
    if(college) {
        redirect_path = '/college' 
    } else if(fees) {
        redirect_path = '/fees'
    } else if(agency) {
        redirect_path = '/agency'
    } else if(finanace) {
        redirect_path = '/finance'
    }

    return (
        <Switch>
            {
                routes.map((item, key) => {
                    const { path, name, component } = item;
                    if (permissions) {
                        switch (name) {
                            case 'Agency': return agency ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'Staff': return staff ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'College': return college ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'Students': return student ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'Fees': return fees ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'Commission': return commission ? <Route key={key} path={path} name={name} component={component} /> : null;
                            case 'Finance': return finanace ? <Route key={key} path={path} name={name} component={component} /> : null;
                            default: return null
                        }
                    } else return null
                })
            }
            <Redirect from="/" to={redirect_path} />
        </Switch>
    )
}

export default App
