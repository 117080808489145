import React, { useEffect } from 'react'
import Navbar from './components/navbar'
import ListStudents from './components/list_students'
import { fetch_fees } from './store/action';
import { useDispatch } from 'react-redux';
// import FeesModal from './components/fees_modal'

function Detail(props) {

    let route_history = props.history
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_fees(route_history, dispatch, 1, [])
    }, [route_history, dispatch])


    return (
        <div className="app-dynamic-layout">
            <Navbar />
            <ListStudents />

        </div>
    )
}

export default Detail
