import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleRounded, ArrowBack, Print, Search } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Navbar from '../../../common-components/navbar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgencyDatas } from '../store/slice';
import { fetch_transactions, print_transactions } from '../store/action';
import { selectCollegeDatas } from '../../college/store/slice';
import { CircularProgress } from '@material-ui/core';
import { selectFinanceDatas } from '../../finance/store/slice';


const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }
}));


function NavbarAgency({ setModal }) {
    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { transaction_filter, transaction_search, transaction_from_date, transaction_to_date, selected_agent ,transaction_financial_year} = useSelector(selectAgencyDatas);
    const { financialYrList } = useSelector(selectFinanceDatas)

    const { arr_colleges } = useSelector(selectCollegeDatas);

    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [PrintButtonSpinner, setPrintButtonSpinner] = useState(false);

    const transaction_fetching_filters = (search = '', filter = 'all', filter_from = '', filter_to = '' , transaction_financial_year= "all") => {
        fetch_transactions(route_history, dispatch, state.id, 1, [], search, filter, filter_from, filter_to ,transaction_financial_year);
    }



    useEffect(() => {
        const checkState = () => {
            // console.log('hi theere',selected_agent)
            if (JSON.stringify(selected_agent) === '{}') {
                route_history.push('/agency')
            }
        }
        checkState();
    }, [selected_agent, route_history])

    const print_btn = () => {
        setPrintButtonSpinner(true);
        const sucess = () => setPrintButtonSpinner(false);
        const failed = () => setPrintButtonSpinner(false);
        print_transactions(route_history, selected_agent.id, transaction_filter, FromDate, ToDate, sucess, failed)
    }

    return (
        <Navbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="/agency" className="app-nav-back-btn">
                    <ArrowBack />
                </Link>
                <h6>{selected_agent.consultant_name}
                {financialYrList && transaction_financial_year ? 
                    financialYrList.find((el)=> el.id===transaction_financial_year) ?
                 `(${financialYrList.find((el)=> el.id===transaction_financial_year).year})` : '' : ''}
                
                </h6>
            </div>

            <div className="app-nav-right">
                <button onClick={() => setModal(true)} className="app-common-btn"><AddCircleRounded style={{ fontSize: 16, marginRight: 5 }} />Add</button>
                <button
                    onClick={() => print_btn()}
                    style={{ width: 90 }}
                    className={PrintButtonSpinner ? "app-common-btn app-btn-green disabled-btn" : "app-common-btn app-btn-green"}>
                    {
                        PrintButtonSpinner ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <>
                                <Print style={{ fontSize: 16, marginRight: 5 }} />Print
                            </>
                        )
                    }
                </button>

                <div style={{ margin: '0px 10px', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10 }}>From</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (ToDate && date) {
                                transaction_fetching_filters(transaction_search, transaction_filter, date, ToDate ,transaction_financial_year);
                            } else {
                                setFromDate(date);
                            }
                        }}
                    />

                    <p style={{ marginLeft: 20, marginRight: 10 }}>To</p>
                    <input
                        type="date"
                        style={{ border: 0, width: 140, height: 35, borderRadius: 10, padding: '0px 10px' }}
                        onChange={txt => {
                            let date = txt.target.value;
                            if (FromDate && date) {
                                transaction_fetching_filters(transaction_search, transaction_filter, FromDate, date.at,transaction_financial_year);
                            } else {
                                setToDate(date);
                            }
                        }}
                    />
                </div>

                <div style={{ margin: '0px 10px' }}>
                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={transaction_filter}
                            className={classes.resize}
                            onChange={txt => {
                                let college_id = txt.target.value;
                                transaction_fetching_filters(transaction_search, college_id, transaction_from_date, transaction_to_date,transaction_financial_year)
                            }}
                        >
                            <MenuItem value='all'>Filter - All</MenuItem>
                            {
                                arr_colleges.map((item, key) => {
                                    const { id, name } = item;
                                    return (
                                        <MenuItem key={key} value={id}>{name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '0px 10px' }}>
                    <FormControl className={classes.formControl}>
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            SelectDisplayProps={{ fontSize: 14 }}
                            value={transaction_financial_year===null ? 'all' : transaction_financial_year}
                            className={classes.resize}
                            onChange={txt => {
                                let year = txt.target.value;
                                transaction_fetching_filters(transaction_search, transaction_filter, transaction_from_date, transaction_to_date ,year)

                            }}
                        >
                            <MenuItem value={'all'}>Fiancial Year Filter - All</MenuItem>
                            {
                                financialYrList.map((item, key) => {
                                    const { id, year } = item
                                    return (
                                        <MenuItem key={key} value={id}>{year}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>

                <div className="app-nav-searchbar" style={{ width :"180px"}}>
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={(txt) => {
                            let keyword = txt.target.value;
                            transaction_fetching_filters(keyword, transaction_filter, transaction_from_date, transaction_to_date,transaction_financial_year);
                        }}
                    />
                    <Search style={{ fontSize: 18 }} />
                </div>

            </div>
        </Navbar>
    )
}

export default NavbarAgency
