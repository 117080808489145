import { configureStore } from '@reduxjs/toolkit';
import agency_data from '../modules/agency/store/slice';
import staff_data from '../modules/staff/store/slice';
import college_data from '../modules/college/store/slice';
import students_data from '../modules/students/store/slice';
import finance_data from '../modules/finance/store/slice';
import commission_data from '../modules/commission/store/slice';
import fees_data from '../modules/fees/store/slice';
import permission_data from '../common-components/store/slice'

export default configureStore({
    reducer: {
        agency: agency_data,
        staff: staff_data,
        college: college_data,
        student: students_data,
        finance: finance_data,
        fees: fees_data,
        commission: commission_data,
        permission: permission_data,
    }
})