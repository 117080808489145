import axios from 'axios';
import auth from '../modules/login/auth';

const Request = (type, url, params, history, success, failed, authorization_recall = true) => {

    let token = auth.getToken();
    console.log('tokennn', token);
    if (token && authorization_recall) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    const success_validation = (type, res, success_callback) => {
        console.log(`${type} Success Response`, res)
        let response = {
            type,
            statusCode: res.status || null,
            response: res.data || null,
            error_dev: null,
            error_app: null
        }
        if (success_callback) {
            success_callback(response);
        }
        return response;
    }

    const failed_validation = (type, res, failed_callback ) => {
        console.log(`${type} Failed Response blsm`, res);
        console.log(`${type} Failed Response`, res.response);
        console.log(`Status Code ${res.statusCode}`);

        if(res.response === undefined && history ) {
            localStorage.clear();
            auth.logout(() => history.push('/login'));
        }

        let statusCode = null;
        let error_dev_data = null;
        let error_app_data = null;

        if (res.data) {
            statusCode = res.data.status
            error_dev_data = res.data.dev_data
            error_app_data = res.data.app_data
        }

        let response = {
            type,
            statusCode: statusCode,
            response:  res.response ? res.response.data : {},
            error_dev: error_dev_data,
            error_app: error_app_data
        }
        if (failed_callback) {
            failed_callback(response);
        }
        return response;
    }

    switch (type) {
        case 'get':
            return axios.get(url)
                .then(response => {
                    return success_validation(type, response, success)
                })
                .catch(error => {
                    return failed_validation( type, error, failed )
                })


        case 'post':
            return axios.post(url, params, { headers: { "Content-Type": "application/json" } })
                .then(response => {
                    return success_validation(type, response, success)
                })
                .catch(error => {
                    return failed_validation( type, error, failed )
                })


        case 'put':
            return axios.put(url, params)
                .then(response => {
                    return success_validation(type, response, success)
                })
                .catch(error => {
                    return failed_validation( type, error, failed )
                })


        case 'delete':
            return axios.delete(url)
                .then(response => {
                    return success_validation(type, response, success)
                })
                .catch(error => {
                    return failed_validation( type, error, failed )
                })

        case 'file-download':
            return axios.get(url, { responseType: 'blob' })
                .then(response => {
                    return success_validation(type, response, success)
                })
                .catch(error => {
                    return failed_validation( type, error, failed )
                })

        default:
            break;
    }

}

export default Request;