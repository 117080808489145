import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import Home from './screens/home'
import Detail from './screens/detail'
import { useSelector } from 'react-redux'
import { selectPermissionDatas } from '../../common-components/store/slice'

function App() {
    const { path } = useRouteMatch()
    const permissions = useSelector(selectPermissionDatas);
    const { agency_detail } = permissions

    return (
        <Switch>
            {agency_detail ? <Route path={path + '/detail'} name="Detail" component={Detail} /> : null}
            <Route path={path} name="home" component={Home} />
        </Switch>
    )
}

export default App
