import React, { useState } from 'react';
import { Delete, Visibility } from '@material-ui/icons';
import Scrollview from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudentsDatas } from '../store/slice'
import { delete_student } from '../store/action';
import { fetch_students } from '../store/action';
import { CircularProgress } from '@material-ui/core';
import { STUDENT_BASE } from '../../../store/endpoint';
import Api from '../../../store/api';

function ListAgency() {

    const {
        arr_students,
        students_loader,
        students_listbottom_loader,
        students_pagination,
        students_page,
        students_filter,
        students_search,
        student_filter_date,
        student_filter_seat,
        student_filter_course,
        student_filter_aadon_course,
        student_filter_batch,
    } = useSelector(selectStudentsDatas);
    const route_history = useHistory();
    const dispatch = useDispatch();
    const [visLoader, setVisLoader] = useState(null);

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_student(route_history, dispatch, id)
            }
        })
    }

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = (scrollHeight - scrollTop) === clientHeight
        console.log(bottom,scrollHeight,scrollTop,clientHeight,scrollHeight - scrollTop)
        if (bottom) {
            if (students_pagination) {
                let page_no_val = students_page + 1;
                fetch_students(route_history, dispatch, page_no_val, arr_students, students_filter, students_search, student_filter_seat, student_filter_date, student_filter_course, student_filter_aadon_course, false, true, student_filter_batch)
            }
        }
    }

    const fetch_studentData = (val) =>{
        setVisLoader(val)
        let api_url = `${STUDENT_BASE}?id=${val}`;
        const success_res = (res) =>{
            const {response} = res;
            setVisLoader(null)
            route_history.push('/students/detail', response)
        }
        Api('get',api_url,'', route_history, success_res)
    }

    return (
        <div className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>Student ID</td>
                            <td style={{ width: 150 }}>Student Name</td>
                            <td style={{ width: 150 }}>College</td>
                            <td style={{ width: 150 }}>Course</td>
                            <td style={{ width: 150 }}>Batch</td>
                            <td style={{ width: 150 }}>Agent</td>
                            <td style={{ width: 200 }}>Manage</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '70vh', position: 'relative' }}>
                    {
                        !students_loader ? (
                            <>
                                {
                                    arr_students.length > 0 ? (
                                        <>
                                        <table className="app_table_main">
                                            <tbody className="app_table_tbody">
                                                {
                                                    arr_students.map((item, key) => {
                                                        const { id, name, college_name, course_name, batch_name, agent_name } = item
                                                        return (
                                                            <tr key={key} className="app-table-row">
                                                                <td style={{ width: 80 }}>ST{id}</td>
                                                                <td style={{ width: 150 }}>{name}</td>
                                                                <td style={{ width: 150 }}>{college_name}</td>
                                                                <td style={{ width: 150 }}>{course_name}</td>
                                                                <td style={{ width: 150 }}>{batch_name}</td>
                                                                <td style={{ width: 150 }}>{agent_name}</td>
                                                                <td style={{ width: 200 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <div onClick={() =>
                                                                            fetch_studentData(item.id)
                                                                             } style={{ textDecoration: 'none', color: '#000' }}>
                                                                            {
                                                                                visLoader===item.id   ?
                                                                                <CircularProgress size={16} style={{ marginRight: 20, cursor: 'pointer' }} />
                                                                                :
                                                                                <Visibility style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                            }
                                                                        </div>

                                                                        {/* <Edit style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} /> */}
                                                                        <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            students_listbottom_loader && (
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                    <CircularProgress size={30} />
                                                </div>
                                            )
                                        }
                                        </>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                        </div>
                                    )
                                }
                            </>

                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                <CircularProgress size={26} />
                            </div>
                        )
                    }

                </Scrollview>
            </div>
        </div>
    )
}

export default ListAgency
