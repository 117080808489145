import Api from '../../../store/api'
import { FINANCE_BASE, FINANCE_CATEGORY_BASE, FINANCE_PRINT, FINANCE_SUB_CATEGORY_BASE, FINANCIAL_YEAR_CRUD } from '../../../store/endpoint';
import {
    remove_single_finance,
    update_single_finance,
    add_single_finance,
    update_finances,
    update_category,
    add_single_category,
    update_single_category,
    remove_single_category,
    update_finances_details,
    update_finances_loader,
    add_single_sub_category,
    update_sub_category,
    update_single_sub_category,
    remove_single_sub_category,
    update_finances_list_loader,
    update_financial_yr,
    create_financial_yr,
    remove_financial_yr,
    add_financial_yr

} from '../store/slice';
import Swal from 'sweetalert2';
import { FileDownloader } from '../../../store/file_downloader';



export const fetch_finance = (history, dispatch, page_no, prev_data = [], search = '', filter_type = 'all', filter_category = 'all',
 filter_subcategory = 'all', filter_from = '', filter_to = '', loader = true, listLoader = false, financialYrFilter = null) => {
    let api_url = `${FINANCE_BASE}?page=${page_no}&limit=20`
    if(financialYrFilter!==null){
        api_url = `${api_url}&financial_year=${financialYrFilter?financialYrFilter:null}`
    }
    if (search) {
        api_url = `${api_url}&key=${search}`
    }
    if (filter_type !== 'all') {
        api_url = `${api_url}&finance_type=${filter_type}`
    }
    if (filter_category !== 'all') {
        api_url = `${api_url}&category=${filter_category}`
    }
    if (filter_subcategory !== 'all') {
        api_url = `${api_url}&sub_category=${filter_subcategory}`
    }
    if (filter_from && filter_to) {
        api_url = `${api_url}&from=${filter_from}&to=${filter_to}`
    }
    dispatch(update_finances_loader({ loader: loader }));
    dispatch(update_finances_list_loader({ loader: listLoader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            const { record, total_expense, total_income } = response;
            let final_data = page_no > 1 ? prev_data.concat(record) : record
            let pagination_status;
            if (record.length < 20) {
                pagination_status = false;
            } else if (record.length === 20) {
                pagination_status = true;
            }
            dispatch(update_finances({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                total_expense,
                total_income,
                search,
                filter_type,
                filter_category,
                filter_subcategory,
                filter_from,
                filter_to,
                finance_filter_year : financialYrFilter
            }))
        } else {
            dispatch(update_finances_loader({ loader: false }))
        }
    })
}


export const print_finance = (history, filter_type = 'all', filter_category = 'all', filter_from = '', filter_to = '', sucess, failed) => {
    let api_url = `${FINANCE_PRINT}?`
    if (filter_type !== 'all') {
        api_url = `${api_url}&finance_type=${filter_type}`
    }
    if (filter_category !== 'all') {
        api_url = `${api_url}&category=${filter_category}`
    }
    if (filter_from && filter_to) {
        api_url = `${api_url}&from=${filter_from}&to=${filter_to}`
    }
    Api('file-download', api_url, '', history).then(res => {
        sucess();
        const { statusCode, response } = res;
        if (statusCode === 200) {
            FileDownloader(response, 'finance_');
        } else {
            if(failed)
                failed()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}

export const fetch_finance_details_action = (history, dispatch) => {
    let api_url = `${FINANCE_BASE}details/`
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            dispatch(update_finances_details({
                obj_response: response,
            }))
        }
    })
}


export const create_finance = (history, dispatch, post_data, success, failed) => {
    Api('post', FINANCE_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_finance({
                new_data: res.response
            }));
        }

    })
}

export const update_finance = (history, dispatch, post_data, finance_id, success, failed) => {
    let api_url = FINANCE_BASE + finance_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Agent Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_finance({
                finance_id: finance_id,
                new_data: res.response
            }))
        }
    })
}

export const delete_finance = (history, dispatch, finance_id) => {
    let api_url = FINANCE_BASE + finance_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Agent deleted!',
                'success'
            )
            dispatch(remove_single_finance({
                finance_id: finance_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}


export const fetch_category = (history, dispatch) => {
    Api('get', FINANCE_CATEGORY_BASE, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            dispatch(update_category({
                arr_response: response
            }))
        }
    })
}

export const create_category = (history, dispatch, post_data, success, failed) => {
    Api('post', FINANCE_CATEGORY_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Category Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_category({
                new_data: res.response
            }));
        } else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }

    })
}

export const update_category_action = (history, dispatch, post_data, category_id, success, failed) => {
    let api_url = FINANCE_CATEGORY_BASE + category_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Category Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_category({
                category_id: category_id,
                new_data: res.response
            }))
        }
    })
}

export const delete_category_action = (history, dispatch, category_id) => {
    let api_url = FINANCE_CATEGORY_BASE + category_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Category deleted!',
                'success'
            )
            dispatch(remove_single_category({
                category_id: category_id,
            }))
        } else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}


// Sub Category

export const fetch_sub_category = (history, dispatch) => {
    Api('get', FINANCE_SUB_CATEGORY_BASE, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            dispatch(update_sub_category({
                arr_response: response
            }))
        }
    })
}

export const create_sub_category = (history, dispatch, post_data, success, failed) => {
    Api('post', FINANCE_SUB_CATEGORY_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Category Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_sub_category({
                new_data: res.response
            }));
        }

    })
}

export const update_sub_category_action = (history, dispatch, post_data, category_id, success, failed) => {
    let api_url = FINANCE_SUB_CATEGORY_BASE + category_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Category Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_sub_category({
                category_id: category_id,
                new_data: res.response
            }))
        }
    })
}

export const delete_sub_category_action = (history, dispatch, category_id) => {
    let api_url = FINANCE_SUB_CATEGORY_BASE + category_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Category deleted!',
                'success'
            )
            dispatch(remove_single_sub_category({
                category_id: category_id,
            }))
        } else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}

export const fetch_financial_years = (history, dispatch) => {
    let api_url = FINANCIAL_YEAR_CRUD;
    Api('get', api_url, '', history).then(res => {
        if(res.statusCode === 200){
            console.log(res)
            dispatch(create_financial_yr({
                financial_yr_list: res.response
            }))
        }
    })
}
export const create_financial_years = (history, dispatch, data, success, failed) => {
    let api_url = FINANCIAL_YEAR_CRUD;
    Api('post', api_url, data, history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'A new Financial Year Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            success()
            fetch_financial_years(history, dispatch)
            dispatch(add_financial_yr({
                new_data: res.response
            }));
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}
export const update_financial_years = (history, dispatch, data, id, success, failed) => {
    let api_url = FINANCIAL_YEAR_CRUD + id + '/';
    Api('put', api_url, data, history).then(res => {
        if(res.statusCode === 200){
            console.log(res)
            success()
            fetch_financial_years(history, dispatch)
            dispatch(update_financial_yr({
                id : id,
                financial_yr: res.response
            }))
        } else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}

export const delete_financial_years = (history, dispatch, financialYrId) => {
    let api_url = FINANCIAL_YEAR_CRUD + financialYrId + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Financial Year deleted!',
                'success'
            )
            fetch_financial_years(history, dispatch)
            dispatch(remove_financial_yr({
                financial_id : financialYrId,
            }))
        } else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data,
            })
        }
    })
}
