// import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import Api from '../../../store/api';
import {
    COLLEGE_BASE,
    COLLEGE_COURSE_BASE,
    COLLEGE_BATCH_BASE,
    COLLEGE_ADDON_COURSE_BASE,
    BASE_MAIN,
    ACADEMIC_YEAR_CRUD
} from '../../../store/endpoint';
import {

    update_colleges,
    add_single_college,
    remove_single_college,
    update_single_college,

    update_academic_yr,
    update_single_academic_yr,
    remove_academic_yr,

    update_college_students,

    update_college_courses,
    add_single_college_course,
    remove_single_college_course,
    update_single_college_course,

    update_addon_course,
    add_single_addon_course,
    remove_single_addon_course,
    update_single_addon_course,

    update_college_batchs,
    add_single_college_batch,
    remove_single_college_batch,
    update_single_college_batch,

    update_college_loader,
    update_college_students_loader,
    update_college_course_loader,
    update_college_batch_loader,

} from '../store/slice';



// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
//                        C O L L E G E
// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>



export const fetch_colleges = (history, dispatch, page_no, prev_data, search = '', limit = 20, loader = true, collegeAcdYr=null) => {
    
    // if(collegeAcdYr===null){
    //     let newDate = new Date()
    //     start_year = dayjs(newDate).format('YYYY-MM-DD')
    //     end_year = dayjs(newDate).add(5,'year').format('YYYY-MM-DD')
    // }
    // else{
    
    let api_url = collegeAcdYr!==null ?
                        `${COLLEGE_BASE}?page=${page_no}&limit=${limit}&year=${collegeAcdYr}` 
                    : 
                        `${COLLEGE_BASE}?page=${page_no}&limit=${limit}`;
    if (search) {
        api_url = `${api_url}&key=${search}`;
    }

    dispatch(update_college_loader({ loader: loader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_colleges({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                search,
                academic_yr : collegeAcdYr
            }))
        } else {
            dispatch(update_college_loader({ loader: false }));
        }

    })
}

export const fetch_all_academic_yr = (history, dispatch) => {
    let api_url = ACADEMIC_YEAR_CRUD;
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = response
            dispatch(update_academic_yr({
                data : final_data
            }))
        } else {
            dispatch(update_academic_yr({ data : [] }));
        }

    })
}

export const update_academic_year = (history, dispatch, id, postData, success, failed) => {
    let api_url = ACADEMIC_YEAR_CRUD + id + '/';
    Api('put', api_url, postData, history, success, failed).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Academic Year Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            
            let final_data = response
            dispatch(update_single_academic_yr({
                data : final_data,
                id : id
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }

    })
}
export const delete_academic_year = (history, dispatch, id) => {
    
    let api_url = ACADEMIC_YEAR_CRUD + id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Academic Year deleted!',
                'success'
            )
            dispatch(remove_academic_yr({
                acd_id: id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }
    })
}


export const create_college = (history, dispatch, post_data, success, failed) => {
    Api('post', COLLEGE_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode >= 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'College Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_college({
                new_data: res.response
            }));
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }

    })
}


export const update_college = (history, dispatch, post_data, college_id, success, failed) => {
    let api_url = COLLEGE_BASE + college_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'College Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_college({
                college_id: college_id,
                new_data: res.response
            }))
        } else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }
    })
}


export const delete_college = (history, dispatch, college_id) => {
    let api_url = COLLEGE_BASE + college_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'College deleted!',
                'success'
            )
            dispatch(remove_single_college({
                college_id: college_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })
        }
    })
}


// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
//          C O L L E G E    S T U D N E T S
// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>



export const fetch_college_students = (history, dispatch, college_id, page_no, prev_data, search = '', loader = true, collegeAcdYr=null) => {
    let api_url = collegeAcdYr ? `${BASE_MAIN}student/crud/college/${college_id}/?page=${page_no}&limit=20&year=${collegeAcdYr}` 
    : `${BASE_MAIN}student/crud/college/${college_id}/?page=${page_no}&limit=20`;
    if (search) {
        api_url = `${api_url}&key=${search}`;
    }
    dispatch(update_college_students_loader({ loader: loader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            const { total_student, direct_students, agency_students, records } = response
            let final_data = page_no > 1 ? prev_data.concat(records) : records
            let pagination_status;
            if (records.length < 20) {
                pagination_status = false;
            } else if (records.length === 20) {
                pagination_status = true;
            }
            dispatch(update_college_students({
                arr_response: final_data,
                total_students: total_student,
                direct_students: direct_students,
                agency_students: agency_students,
                page_no,
                pagination: pagination_status,
                search
            }))
        } else {
            dispatch(update_college_students_loader({ loader: false }));
        }
    })
}



// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
//            C O L L E G E    C O U R S E
// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>



export const fetch_college_courses = (history, dispatch, college_id, page_no, prev_data, search = '', loader = true, collegeAcdYr=null) => {
    //let api_url = `${COLLEGE_COURSE_BASE}?page=${page_no}&limit=20`;
    // let api_url = `${BASE_MAIN}/?`
    let api_url = collegeAcdYr ?    `${BASE_MAIN}college/course/crud/college/${college_id}/?page=${page_no}&limit=40&year=${collegeAcdYr}`
    : `${BASE_MAIN}college/course/crud/college/${college_id}/?page=${page_no}&limit=40`;
    if (search) {
        api_url = `${api_url}&key=${search}`;
    }
    dispatch(update_college_course_loader({ loader: loader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_college_courses({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                search
            }))
        } else {
            dispatch(update_college_course_loader({ loader: false }));
        }
    })
}


export const create_college_course = (history, dispatch, post_data, success, failed) => {
    Api('post', COLLEGE_COURSE_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Course Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_college_course({
                new_data: res.response
            }));
        }
        else {
            if(failed)
                failed();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }

    })
}


export const update_college_course = (history, dispatch, post_data, course_id, success, failed) => {
    let api_url = COLLEGE_COURSE_BASE + course_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Course Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_college_course({
                course_id: course_id,
                new_data: res.response
            }))
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })

        }
    })
}


export const delete_college_course = (history, dispatch, course_id) => {
    let api_url = COLLEGE_COURSE_BASE + course_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Course deleted!',
                'success'
            )
            dispatch(remove_single_college_course({
                course_id: course_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}




// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
//            A A D D O N    C O U R S E
// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>



export const fetch_clg_addon_courses = (history, dispatch, course_id) => {
    let api_url = `${BASE_MAIN}college/course/${course_id}/addon/crud/?page=${1}&limit=50`;
    Api('get', api_url, '', history).then(res => {
        const { response } = res
        dispatch(update_addon_course({
            arr_response: response || [],
        }))
    })
}


export const create_clg_addon_course = (history, dispatch, post_data, success, failed) => {
    Api('post', COLLEGE_ADDON_COURSE_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Ad-On Course Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_addon_course({
                new_data: res.response
            }));
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }

    })
}


export const update_clg_addon_course = (history, dispatch, post_data, course_id, success, failed) => {
    let api_url = COLLEGE_ADDON_COURSE_BASE + course_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Ad-On Course Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_addon_course({
                course_id: course_id,
                new_data: res.response
            }))
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }
    })
}


export const delete_clg_addon_course = (history, dispatch, course_id) => {
    let api_url = COLLEGE_ADDON_COURSE_BASE + course_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Ad-On Course deleted!',
                'success'
            )
            dispatch(remove_single_addon_course({
                course_id: course_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}



// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
//            C O L L E G E    B A T C H
// =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>



export const fetch_college_batches = (history, dispatch, college_id, page_no, prev_data, search = '', loader = true, course_id=null, collegeAcdYr=null) => {
    // let api_url = `${COLLEGE_BATCH_BASE}?page=${page_no}&limit=20`;
    // let api_url = `${BASE_MAIN}college/${college_id}/course/batch/crud/?page=${page_no}&limit=40`;
    let api_url = collegeAcdYr ?    `${BASE_MAIN}college/${college_id}/course/batch/crud/?page=${page_no}&limit=40&year=${collegeAcdYr}`
    :   `${BASE_MAIN}college/${college_id}/course/batch/crud/?page=${page_no}&limit=40`;

    if (search) {
        api_url = `${api_url}&key=${search}`;
    }
    if (course_id!==null){
        api_url = `${BASE_MAIN}college/course/${course_id}/batch/crud/?page=${page_no}&limit=40`
    }
    dispatch(update_college_batch_loader({ loader: loader }));
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res
        if (statusCode === 200) {
            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if (response.length < 20) {
                pagination_status = false;
            } else if (response.length === 20) {
                pagination_status = true;
            }
            dispatch(update_college_batchs({
                arr_response: final_data,
                page_no,
                pagination: pagination_status,
                search,
                course_filter : course_id
            }))
        } else {
            dispatch(update_college_batch_loader({ loader: false }));
        }

    })
}


export const create_college_batch = (history, dispatch, post_data, success, failed) => {
    Api('post', COLLEGE_BATCH_BASE, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Batch Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(add_single_college_batch({
                new_data: res.response
            }));
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }

    })
}


export const update_college_batch = (history, dispatch, post_data, batch_id, success, failed) => {
    let api_url = COLLEGE_BATCH_BASE + batch_id + '/';
    Api('put', api_url, post_data, history, success, failed).then(res => {
        if (res.statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Batch Details Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_college_batch({
                batch_id: batch_id,
                new_data: res.response
            }))
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.response.app_data
            })

        }
    })
}


export const delete_college_batch = (history, dispatch, batch_id) => {
    let api_url = COLLEGE_BATCH_BASE + batch_id + '/';
    Api('delete', api_url, '', history).then(res => {
        if (res.statusCode === 200) {
            Swal.fire(
                'Deleted!',
                'Batch deleted!',
                'success'
            )
            dispatch(remove_single_college_batch({
                batch_id: batch_id,
            }))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.error_app,
            })
        }
    })
}



export const batch_status_change = (history, dispatch, batch_id) => {
    let api_url = `${BASE_MAIN}college/course/batch/status/change/${batch_id}/`;
    Api('get', api_url, '', history).then(res => {
        const { response, statusCode } = res;
        if (statusCode === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Batch Status Updated',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(update_single_college_batch({
                batch_id: batch_id,
                new_data: response
            }))
        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.app_data
            })

        }
    })
}