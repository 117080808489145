import React, { useState, useEffect } from 'react'
import NavbarAgency from './components/navbar'
import ListAgency from './components/list'
import StaffModal from './components/commission_modal'
import { fetch_commission } from './store/action';
import { useDispatch } from 'react-redux';

function App(props) {
    const [Modal, setModal] = useState(false)

    let route_history = props.history
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_commission(route_history, dispatch, 1, [])
    }, [route_history, dispatch])



    return (
        <div className="app-dynamic-layout">
            <NavbarAgency modalStatus={setModal} />
            <ListAgency modalStatus={setModal} />
            <StaffModal
                modalStatus={Modal}
                modalAction={setModal}

            />
        </div>
    )
}

export default App
