import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
// import { AccountCircle } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField';
import { useHistory} from 'react-router';
import { BASE_MAIN} from '../store/endpoint';
import Api from '../store/api'
import Swal from 'sweetalert2';



import './styles.css'


const useStyles = makeStyles((theme) => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function ProfileModal({
    modalStatus, 
    modalAction, 
    profilename,
    email,

    
    
}) {

    const classes = useStyles();


    const handleClose = () => {
        modalAction(false)
    };

    const router_history = useHistory();

    const [Password,setPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const[buttonLoader, setbuttonLoader] = useState(false);

    //let passRegx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    const profile_update = () => {
        if(NewPassword  === ConfirmPassword) {

            let postData = {
                password : Password,
                new_password : NewPassword,  
            }

            

            const success = () => {
                setbuttonLoader(false);
                setPassword('')
                setNewPassword('')
                setConfirmPassword('')
                setError('')
                handleClose()       
            }
            const failed = () => { 
                setError('Error Occured')              
            }

            if(postData)
                {
                    setbuttonLoader(true);
                    let api_url = `${BASE_MAIN}staff/password/change/`;
                    Api('post', api_url, postData,router_history, success,failed).then(res => {
                        const { response, statusCode } = res
                        if(statusCode === 200 ) {
                            if(response.app_data !== 'Password Changed Successfully') {
                                    Swal.fire({
                                    icon: 'error',
                                    title: 'Current Password Invalid',              
                                    })
                            } else {
                                Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'password updated sucessfully',
                                showConfirmButton: false,
                                timer: 1500
                                })
                            }
                            
                        } 
                    })
                } 

        } else {
            setError('Invalid password or passwords does not match ')
        }

    }

    let update_btn_status = false;
    if(Password && NewPassword && ConfirmPassword) {
        update_btn_status = true;
    }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
       
      >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}> Profile</h4>
                
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField 
                        id="standard-basic" 
                        label="Username"
                        value={profilename} 
                        disabled
                        // onChange={txt => setUsername(txt.target.value)}
                    />
                    
                  
                    <TextField 
                        id="standard-basic" 
                        label="Email" 
                        type="email"
                        value={email}
                        disabled
                        // onChange={txt => setEmail(txt.target.value)}
                        // helperText={EmailError}
                    />
                      <TextField 
                        // error={PasswordError ? true : false}
                        id="standard-basic" 
                        label="Current Password" 
                        type="password"
                        value={Password}
                        onChange={txt => setPassword(txt.target.value)}
                        // helperText={PasswordError}
                    />
                      <TextField 
                        id="standard-basic" 
                        label="New Password" 
                        type="text"
                        value={NewPassword}
                        onChange={txt => {
                            setNewPassword(txt.target.value)
                        }}
                    />
                      
                       <TextField 
                        error={error ? true : false}
                        id="standard-basic" 
                        label="Confirm Password" 
                        type="password"
                        value={ConfirmPassword}
                        onChange={txt => setConfirmPassword(txt.target.value)}
                        helperText={error}
                    />

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    { update_btn_status ? (
                                <button className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} 
                                    onClick={() => { profile_update()
                                }}> 
                                {
                                    buttonLoader ? ( <CircularProgress size={20} color="inherit" /> ) : ('Update')
                                }
                                </button>
                        ) : (<button className="submit-btn disabled-btn"> Update</button>)
                    }
                </div>
            
            </div>

      </Modal>
  );
}
export default ProfileModal