import { createSlice } from '@reduxjs/toolkit';

export const commissionSlice = createSlice({
    name: 'commission',
    initialState: {
        arr_commission: [],
        commission_page: 1,
        commission_search: '',
        commission_type: 'all',
        commission_pagination: true,
        commission_loader: false,
        commission_list_loader: false
    },
    reducers: {
        update_commission: (state, action) => {
            const { arr_response, page_no, pagination, search, commission } = action.payload;
            state.arr_commission = arr_response;
            state.commission_page = page_no;
            state.commission_search = search;
            state.commission_pagination = pagination;
            state.commission_type = commission;
            state.commission_loader = false;
            state.commission_list_loader = false;
        },

        update_commission_loader: (state, action) => {
            const { loader } = action.payload;
            state.commission_loader = loader;
        },

        update_commission_list_loader: (state, action) => {
            const { loader } = action.payload;
            state.commission_list_loader = loader;
        },

        update_single_commission: (state, action) => {
            const { commission_id, new_data } = action.payload;
            let array_item = state.arr_commission.find(item => item.id === commission_id);
            let arra_item_index = state.arr_commission.indexOf(array_item);
            state.arr_commission[arra_item_index] = new_data;
        },
    }
})

export const {
    update_commission,
    update_commission_loader,
    update_commission_list_loader,
    update_single_commission,

} = commissionSlice.actions

export const selectcommissionDatas = state => state.commission;

export default commissionSlice.reducer;