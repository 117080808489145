import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { create_fees } from '../store/action';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core'

import './style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function FeesModal({
    modalStatus,
    modalAction,
    data,
    College,
    setCollege,
    CollegeList,
    setCollegeList,
    fetch_courses,
    fetch_batches,
    fetch_Studentdata,
    Course,
    setCourse,
    CourseList,
    // setCourseList,
    Amount,
    setAmount,
    Batch,
    setBatch,
    BatchList,
    // setBatchList,
    Student,

    search,
    setSearch,

    setStudent,
    StudentList,
    // setStudentList          

    Description,
    setDescription,

    Col,
    setCol,

    Modeofpay,
    setModeofpay,
    onSelect


}) {






    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };

    const router_history = useHistory();
    const dispatch = useDispatch();
    const [buttonLoader, setButtonLoader] = useState(false);
    const searchcontainer = useRef(null);
    const [isAdmissionFee, setIsAdmissionFee] = useState(false)
    // const [search, setSearch] = useState("");
    const [StudentSelectionError, setStudentSelectionError] = useState("");


    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside)
        return () => {
            window.removeEventListener("mousedown", handleClickOutside)
        }
    });

    const handleClickOutside = event => {
        if (searchcontainer.current && !searchcontainer.current.contains(event.target)) {
            hideSuggestion();
        }
    }
    const [Visibility, setVisibility] = useState(false);
    const hideSuggestion = () => setVisibility(false);
    const showSuggestion = () => setVisibility(true);
    const [SuggestionList, setSuggestionList] = useState([]);

    const suggestion = (text) => {
        if (!text) {
            setSuggestionList([]);
        }
        else {

            let matches = StudentList.filter((item) => {
                const regex = new RegExp(`${text}`, "gi");
                return item.name.match(regex)
            });
            let final_list_data = [];
            matches.map((item_val, length) => {
                // if(length < 4) {
                final_list_data.push(item_val);
                // }
                return null;
            })
            setSuggestionList(final_list_data);
        }
    }


    const button_fx = () => {

        if (Student) {

            setButtonLoader(true);
            let postData = {

                college: College,
                course: Course,
                batch: Batch,
                student: Student,
                amount: Amount,
                description: Description,
                collection_place: Col,
                mode_of_payment: Modeofpay
            }
            if(isAdmissionFee)
                postData = {...postData, admission_fees : 1}

            const success = () => {

                setButtonLoader(false);
                setCollege('')
                setCourse('')
                setBatch('')
                setStudent('')
                setAmount('')
                setDescription('')
                setCol('')
                setModeofpay('')
                setIsAdmissionFee(false)
                handleClose()
            }
            const failed = () => {

                setButtonLoader(false);
                setCollege('')
                setCourse('')
                setBatch('')
                setStudent('')
                setAmount('')
                setDescription('')
                setCol('')
                setModeofpay('')
                setIsAdmissionFee(false)
                handleClose()
            }

            if (postData) {
                create_fees(router_history, dispatch, postData, success, failed)
            }

        } else {
            setSearch('')
            setStudentSelectionError('Select a student!')
        }
    }


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>Add Fees</h4>
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        select
                        label="College"
                        value={College}
                        onChange={txt => {
                            setCollege(txt.target.value)
                            fetch_courses(txt.target.value)
                        }}
                    >
                        <MenuItem value={''}>Choose College</MenuItem>
                        {

                            CollegeList && CollegeList.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id}>{name}</MenuItem>
                                )
                            })
                        }
                    </TextField>

                    <TextField
                        id="standard-basic"
                        select
                        label="Course"
                        value={Course}
                        onChange={txt => {
                            setCourse(txt.target.value)
                            fetch_batches(txt.target.value)
                        }}

                    >
                        <MenuItem value={''}>Choose Course</MenuItem>
                        {
                            CourseList && CourseList.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id}>{name}</MenuItem>
                                )
                            })
                        }
                    </TextField>


                    <TextField
                        id="standard-basic"
                        select
                        label="Batch"
                        value={Batch}
                        onChange={txt => {
                            setBatch(txt.target.value)
                            fetch_Studentdata(txt.target.value)

                        }}
                    >
                        <MenuItem value={''}>Choose Course</MenuItem>
                        {
                            BatchList && BatchList.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id}>{name}</MenuItem>
                                )
                            })
                        }

                    </TextField>

                    <div style={{ height: "100%", position: 'relative' }} ref={searchcontainer}>
                        <TextField
                            error={StudentSelectionError ? true : false}
                            type="text"
                            id="standard-basic"
                            label="Student List"
                            fullWidth
                            value={search}
                            onClick={showSuggestion}
                            onChange={e => { setSearch(e.target.value); suggestion(e.target.value); setStudent('') }}
                            helperText={StudentSelectionError}
                        />

                        {Visibility && SuggestionList.length > 0 && (
                            <div className="suggestion-main-layout">
                                <ul className="suggestion-list-container">
                                    {
                                        SuggestionList && SuggestionList.map((item, key) => {
                                            return (
                                                <>
                                                    <li
                                                        className="student-suggestion-list"
                                                        key={key}
                                                        onClick={() => {
                                                            hideSuggestion();
                                                            setSearch(item.name);
                                                            setStudent(item.id)
                                                        }}
                                                    >
                                                        {item.name} - ({item.id})
                                                    </li>
                                                    <div style={{ height: 0.5, backgroundColor: 'grey' }} />
                                                </>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )}

                    </div>

                    <TextField
                        id="standard-basic"
                        label="Amount"
                        type="number"
                        value={Amount}
                        onChange={txt => setAmount(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        label="Description"

                        value={Description}
                        onChange={txt => setDescription(txt.target.value)}
                    />



                    <TextField
                        id="standard-basic"
                        label="Collection place"

                        value={Col}
                        onChange={txt => setCol(txt.target.value)}
                    />


                    <TextField
                        id="standard-basic"
                        select
                        label="Mode of pay"
                        value={Modeofpay}
                        onChange={txt => setModeofpay(txt.target.value)}
                    >

                        <MenuItem value={''}>Choose Mode</MenuItem>
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="UPI">UPI</MenuItem>
                        <MenuItem value="Cheque">Cheque</MenuItem>
                        <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    </TextField>

                    <FormControlLabel
                        control={
                            <Checkbox checked={isAdmissionFee} onChange={v => setIsAdmissionFee(!isAdmissionFee)} name="admissionFee" />
                        }
                        label="Admission Fee"
                    />
                    

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        search && Amount && Modeofpay ? (
                            <button
                                onClick={() => button_fx()}
                                className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                                {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : 'Create'}
                            </button>
                        ) : (
                            <button className="submit-btn disabled-btn" > Create </button>
                        )
                    }


                </div>


            </div>
        </Modal>
    );
}



export default FeesModal
