export const FileDownloader = (file, file_name) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    let cur_date = new Date();
    let file_extension = 'csv'
    let date_string = `${cur_date.getDate()}${cur_date.getMonth()}${cur_date.getFullYear()}-${cur_date.getHours()}${cur_date.getMinutes()}${cur_date.getSeconds()}`
    let file_name_final = `${file_name}${date_string}.${file_extension}`
    link.setAttribute('download', file_name_final); //or any other extension
    document.body.appendChild(link);
    link.click();
}