import React, { useEffect } from 'react'
import NavbarAgency from '../components/navbar'
import ListAgency from '../components/list'
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetch_students, fetch_colleges, fetch_all_courses, fetch_all_addon_courses } from '../store/action'
import { fetch_all_academic_yr } from '../../college/store/action';

function Home() {

    const route_history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch_colleges(route_history, dispatch)
        fetch_all_courses(route_history, dispatch)
        fetch_all_addon_courses(route_history, dispatch)
        fetch_students(route_history, dispatch, 1, [])
        fetch_all_academic_yr(route_history, dispatch)
    },[route_history, dispatch])

    return (
        <div className="app-dynamic-layout" style={{ position : 'relative' }}>
            <NavbarAgency />
            <ListAgency />
        </div>
    )
}

export default Home
