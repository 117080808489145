import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CircularProgress } from '@material-ui/core';
import './style.css'
import Scrollbars from 'react-custom-scrollbars';
import { selectStudentsDatas } from '../store/slice';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { print_all_fee_recipt, print_individual_fee_recipt } from '../store/action';
import dayjs from 'dayjs';




const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function TransactionModal({
    modalStatus,
    modalAction,
    data,
    // scrolling
}) {

    const classes = useStyles();
    const route_history = useHistory();
    const { state } = useLocation();

    const [PrintButtonLoader, setPrintButtonLoader] = useState(false);
    const [IndividualPrintButtonLoader, setIndividualPrintButtonLoader] = useState(false);
    const { arr_student_fee_general_info } = useSelector(selectStudentsDatas);

    const handleClose = () => {
        modalAction(false);
    };

    const print_all_recipts = () => {
        setPrintButtonLoader(true);
        const success = () => setPrintButtonLoader(false);
        const failed = () => setPrintButtonLoader(false)
        print_all_fee_recipt(route_history, state.id, success, failed)
    }

    const individual_recipt_print_fx = (id) => {
        setIndividualPrintButtonLoader(true);
        const success = () => setIndividualPrintButtonLoader(false);
        const failed = () => setIndividualPrintButtonLoader(false)
        print_individual_fee_recipt(route_history, id, success, failed)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div style={{ width: data && data.length!==0 ? '60vw' : 'auto' }} className="app-modal-main">
                <h5 style={{ margin: '10px 0px' }}>Payments</h5>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display:'flex', flexDirection : 'row', justifyContent: 'flex-start' }}>
                    <div style={{ backgroundColor: '#dcdedc', borderRadius: 20, padding: 10 }}>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Paid Amount : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.paid_amount || 0}</span></p>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Due Amount : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.total_due || 0}</span></p>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Fee : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.total || 0}</span></p>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Current Due {`till ${arr_student_fee_general_info.current_duration}`} : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.total_due || 0}</span></p>
                    </div>
                    <div style={{ backgroundColor: '#dcdedc', borderRadius: 20, padding: 10, marginLeft : 5 }}>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Addmission Fee Paid : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.admission_fees_paid || 0}</span></p>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Addmission Fee Due : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.admission_fees_due || 0}</span></p>
                        <p style={{ fontSize: 14, marginTop: 10 }}>Total Addmission Fee : <span style={{ fontWeight: 'bold' }}>{arr_student_fee_general_info.total_admission_fees || 0}</span></p>
                    </div>
                    
                    </div>
                    {   data && data.length!==0 ?
                    <button
                        onClick={() => print_all_recipts()}
                        style={{
                            width: 100,
                            pointerEvents: PrintButtonLoader ? 'none' : '',
                            opacity: PrintButtonLoader ? 0.4 : 1
                        }}
                        className="app-common-btn app-btn-green">
                        {
                            PrintButtonLoader ? (
                                <CircularProgress size={14} color="inherit" />
                            ) : (
                                'Print'
                            )
                        }
                    </button>
                    :   null
                    }
                </div>


                {   data && data.length!==0 ?
                <Scrollbars
                    // onScroll={scrolling} 
                    style={{ height: '40vh', marginTop: 20 }}>
                    {
                        data.map((item, key) => {
                            const { id, amount, updated_at, description, mode_of_payment, collection_place } = item;
                            // let date_val = new Date(updated_at);
                            // let date_z = date_val.getDate() + '-' + date_val.getMonth() + '-' + date_val.getFullYear();
                            let date_val = new Date(updated_at);
                            let date_z = dayjs(date_val).format('DD/MM/YYYY');
                            return (
                                <div key={key} style={{ display: 'flex', alignItems: 'center', fontSize: 12, marginBottom: 10 }}>
                                    <p style={{ flex: 0.5 }} >{key + 1}</p>
                                    <p style={{ flex: 1 }}>{date_z}</p>
                                    <p style={{ flex: 2 }}>{description}</p>
                                    <p style={{ flex: 1, fontWeight: 'bold' }}>{amount}</p>
                                    <p style={{ flex: 1 }}>{mode_of_payment}</p>
                                    <p style={{ flex: 1 }}>{collection_place}</p>
                                    <button
                                        onClick={() => individual_recipt_print_fx(id)}
                                        style={{
                                            height: 25,
                                            width: 70,
                                            padding: 0,
                                            pointerEvents: IndividualPrintButtonLoader ? 'none' : '',
                                            opacity: IndividualPrintButtonLoader ? 0.4 : 1
                                        }}
                                        className="app-common-btn">
                                        {
                                            IndividualPrintButtonLoader ? (
                                                <CircularProgress size={14} color="inherit" />
                                            ) : (
                                                'Print'
                                            )
                                        }
                                    </button>
                                </div>
                            )
                        })
                    }
                </Scrollbars>
                :   null
                }
            </div>
        </Modal>
    );
}



export default TransactionModal
