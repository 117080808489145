import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
// import './style.css'
// import Scrollbars from 'react-custom-scrollbars';
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
function StudentModal({
    modalStatus,
    modalAction,
    data,
    Created


}) {

    const classes = useStyles();


    const handleClose = () => {
        modalAction(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >







            <div className="app-modal-main">

                <h4 style={{ margin: 0, marginBottom: 20 }}> Fees Reciept</h4>

                <form className={classes.root} noValidate autoComplete="off">


                    <TextField
                        id="standard-basic"

                        label="Fees ID"
                        value={data.id}

                    >

                    </TextField>

                    <TextField
                        id="standard-basic"
                        label="Date"
                        value={Created}


                    >

                    </TextField>

                    <TextField
                        id="standard-basic"
                        label="Student ID"
                        value={data.student}

                    ></TextField>


                    <TextField
                        id="standard-basic"
                        label="Student Name"
                        value={data.student_name}

                    >


                    </TextField>
                    <TextField
                        id="standard-basic"

                        label="Collection_place"
                        value={data.collection_place}

                    //value={Student}

                    >


                    </TextField>


                    <TextField
                        id="standard-basic"
                        label="College Name"

                        //value={Amount}
                        value={data.college_name}


                    />

                    <TextField
                        id="standard-basic"
                        label="Course Name"
                        value={data.course_name}


                    // value={}

                    />



                    <TextField
                        id="standard-basic"
                        label="Batch Namr"
                        value={data.batch_name}


                    //value={Col}

                    />

                    <TextField
                        id="standard-basic"
                        label="Mode of pay"
                        value={data.mode_of_payment}

                    >

                    </TextField>

                    <TextField
                        id="standard-basic"
                        label="Mode of pay"
                        value={data.amount}

                    >

                    </TextField>


                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <button
                        className="submit-btn">Print
                    </button>

                </div>


            </div>



        </Modal>
    );
}



export default StudentModal
