import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import { add_academic_yr } from '../store/action';
import { CircularProgress } from '@material-ui/core'
import { fetch_all_academic_yr, update_academic_year } from '../../college/store/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCollegeDatas } from '../../college/store/slice';
import { EditRounded } from '@material-ui/icons';
import Scrollview from 'react-custom-scrollbars';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function AcademicYearModal({
    modalStatus,
    modalAction
}) {



    const classes = useStyles();
    const dispatch = useDispatch();
    const { college_acd_yr_list } = useSelector(selectCollegeDatas) 

    const handleClose = () => {
        setYear('')
        setYearData(null)
        modalAction(false)
    };

    let router_history = useHistory()
    const [buttonLoader, setButtonLoader] = useState(false);
    const [yearData, setYearData] = useState(null)
    const [year, setYear] = useState('')

    // let year_data = '';

    // if (data) {
    //     year_data = data.year;
    // }



    const button_fx = () => {

        setButtonLoader(true);


        const success = () => {
            setButtonLoader(false);
            fetch_all_academic_yr(router_history, dispatch)
            setYearData('')
            handleClose()
        }
        const failed = () => {
            setButtonLoader(false);
            handleClose()
        }

            
        let postData = {
            year : year
        }

        if (yearData) {
            // console.log('redy for update api call', postData)
            update_academic_year(router_history, dispatch, yearData.id, postData, success, failed)
        } else {
            // console.log('redy for create api call', postData)
            add_academic_yr(router_history, postData, success, failed)
        }
    }

    let add_btn_status = false;
    if (year) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (year) {
        update_btn_status = true
    }

    let general_button = false;
    yearData ? general_button = update_btn_status : general_button = add_btn_status


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>Academic Year</h4>
                <form className={classes.root} noValidate autoComplete="off">
                <Scrollview style={{ height: '20vh', position: 'relative' }}>
                    <table className="app_table_main">
                        <tbody className="app_table_tbody">
                            {
                                college_acd_yr_list && college_acd_yr_list.map((item, key) => {
                                    return (
                                        <tr key={key} className="app-table-row">
                                            <td style={{ width: 90 }}>{item.year}</td>
                                            <td style={{ width: 10 }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <EditRounded style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} 
                                                    onClick={()=> { 
                                                        setYearData(item); setYear(item.year);
                                                        
                                                        }} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>                                        
                </Scrollview>
                    <TextField
                        id="standard-basic"
                        label="Academic Year"
                        value={year}
                        helperText="eg: 2021-22"
                        onChange={txt => setYear(txt.target.value)}
                    />

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (yearData ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{yearData ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default AcademicYearModal
