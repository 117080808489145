import React, { useEffect, useState } from 'react'
import NavbarDetail from '../components/navbar_detail'
import ListTransaction from '../components/list_transaction'
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_transactions } from '../store/action';
import TransactionModal from '../components/transaction_modal';
import { selectAgencyDatas } from '../store/slice';
import { selectFinanceDatas } from '../../finance/store/slice';

function Detail() {
    const { state } = useLocation();
    const route_history = useHistory();
    const dispatch = useDispatch();

    const [Modal, setModal] = useState(false);
    const [Amount, setAmount] = useState('');
    const [Modeofpay, setModeofpay] = useState('');
    const [financialYear, setfinancialYear] = useState('');

    const [SelectedTransaction, setSelectedTransaction] = useState('');
    const {agency_financial_yr} = useSelector(selectAgencyDatas);

    const { financialYrList } = useSelector(selectFinanceDatas)




    useEffect(() => {
        const defaultApiCall = () => {
            if (state) {
                console.log("agency_financial_year",agency_financial_yr,)
                fetch_transactions(route_history, dispatch, state.id, 1 ,[], "" , "all",'','',agency_financial_yr);
            }
        }
        defaultApiCall();
    }, [route_history, dispatch, state,agency_financial_yr]);

    return (
        <div className="app-dynamic-layout">
            <NavbarDetail
                setModal={setModal}
            />
            <ListTransaction

                setSelectedTransaction={setSelectedTransaction}
                Amount={Amount}
                setAmount={setAmount}
                setModal={setModal}
                Modeofpay={Modeofpay}
                setModeofpay={setModeofpay}
                // financialYear={financialYear}
                setfinancialYear={setfinancialYear}
                // agency_financial_year={agency_financial_year}

                
             
            />
            <TransactionModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedTransaction}
                Amount={Amount}
                setAmount={setAmount}
                Modeofpay={Modeofpay}
                setModeofpay={setModeofpay}
                financialYear={financialYear}
                setfinancialYear={setfinancialYear}
                financialYrList={financialYrList}

            />
        </div>
    )
}

export default Detail
