// import { Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import Scrollview from 'react-custom-scrollbars';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollegeDatas } from '../store/slice';
import CourseModal from './course_modal';
import { useHistory } from 'react-router';
import { delete_college_course, fetch_clg_addon_courses, fetch_college_courses } from '../store/action';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

function TabCourseList() {

    const { arr_college_course, college_course_loader, college_course_page, college_course_pagination, college_course_search, college_acd_yr } = useSelector(selectCollegeDatas);
    const route_history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const [Modal, setModal] = useState(false)
    const [SelectedCourse, setSelectedCourse] = useState({})

    const [College, setCollege] = useState('')
    const [Name, setName] = useState('')
    const [GraduationType, setGraduationType] = useState('')
    const [DurationType, setDurationType] = useState('')
    const [Duration, setDuration] = useState('')

    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            console.log('add-on-course', result)
            if (result.isConfirmed) {
                delete_college_course(route_history, dispatch, id);
            }
        })
    }


    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (college_course_pagination) {
                let page_no_val = college_course_page + 1;
                fetch_college_courses(route_history, dispatch, state.college_id, page_no_val, arr_college_course, college_course_search, false, college_acd_yr)
            }
        }
    }

    return (
        <div style={{ padding: '0px', margin: 0, height: '72vh' }} className="app-main-list-layout">
            <div className="app-table-layout">

                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            <td style={{ width: 80 }}>Course Id</td>
                            <td style={{ width: 150 }}>Course Name</td>
                            <td style={{ width: 150 }}>UG / PG</td>
                            <td style={{ width: 150 }}>Year / Sem</td>
                            <td style={{ width: 150 }}>Duration</td>
                            <td style={{ width: 150 }}>No . Students</td>
                            <td style={{ width: 200 }}>Manage</td>
                        </tr>
                    </tbody>
                </table>

                <Scrollview
                    onScroll={e => ScrollPos(e)}
                    style={{ height: '68vh', position: 'relative' }}>
                    {!college_course_loader ? (
                        <>
                            {arr_college_course.length > 0 ? (
                                <table className="app_table_main">
                                    <tbody className="app_table_tbody">
                                        {
                                            arr_college_course.map((item, key) => {
                                                const { id, name, college, graduation_type, duration_type, duration, total_students, } = item;
                                                return (
                                                    <tr key={key} className="app-table-row">
                                                        <td style={{ width: 80 }}>CR{id}</td>
                                                        <td style={{ width: 150 }}>{name}</td>
                                                        <td style={{ width: 150 }}>{graduation_type}</td>
                                                        <td style={{ width: 150 }}>{duration_type}</td>
                                                        <td style={{ width: 150 }}>{duration}</td>
                                                        <td style={{ width: 150 }}>{total_students}</td>
                                                        <td style={{ width: 200 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div className="add-on-course-btn"
                                                                    onClick={() => {
                                                                        setSelectedCourse(item)
                                                                        setName(name);
                                                                        setGraduationType(graduation_type);
                                                                        setDurationType(duration_type)
                                                                        setDuration(duration)
                                                                        setCollege(college)
                                                                        fetch_clg_addon_courses(route_history, dispatch, id)
                                                                        setModal(true)
                                                                    }}
                                                                    style={{ fontSize: 10, marginRight: 2, cursor: 'pointer' }}
                                                                >
                                                                    Edit/Add Ad on Course
                                                                </div>
                                                                <div onClick={() => delete_fx(id)} className="add-on-course-btn" style={{ fontSize: 12, marginRight: 20, cursor: 'pointer', backgroundColor: 'red' }} >Delete</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={26} />
                        </div>
                    )}
                </Scrollview>
            </div>

            <CourseModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedCourse}
                College={College}
                setCollege={setCollege}
                Name={Name}
                setName={setName}
                GraduationType={GraduationType}
                setGraduationType={setGraduationType}
                DurationType={DurationType}
                setDurationType={setDurationType}
                Duration={Duration}
                setDuration={setDuration}
            />

        </div>
    )
}

export default TabCourseList
