import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { selectCollegeDatas } from '../store/slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { create_college_batch, update_college_batch } from '../store/action';
import { CircularProgress } from '@material-ui/core'
import dayjs from 'dayjs'


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function BatchModal({
    modalStatus,
    modalAction,
    data,
    College,
    setCollege,
    BatchName,
    setBatchName,
    Course,
    setCourse,
    BatchFrom,
    setBatchFrom,
    BatchTo,
    setBatchTo,
    date_final


}) {

    const classes = useStyles();
    let router_history = useHistory()
    let dispatch = useDispatch()

    const [buttonLoader, setButtonLoader] = useState(false);
    const { arr_college_course } = useSelector(selectCollegeDatas);

    const handleClose = () => {
        setBatchName('')
        setCourse('')
        setBatchFrom(date_final)
        setBatchTo(date_final)
        modalAction(false)
    };

    let name = '';
    let course_id = '';
    let batch_from = '';
    let batch_to = '';
    let from_date = '';

    if (data) {
        name = data.name || '';
        course_id = data.course || '';
        batch_from = data.from_date || '';
        batch_to = data.to_date || '';
    }

    const button_fx = () => {
        setButtonLoader(true);

        let postData = {
            name: BatchName,
            course: Course,
            from_date: BatchFrom,
            to_date: BatchTo
        }

        const success = () => {
            setButtonLoader(false);
            handleClose()
        }
        const failed = () => {
            setButtonLoader(false);
            handleClose()
        }

        if (data) {
            // console.log('redy for update api call', postData)
            update_college_batch(router_history, dispatch, postData, data.id, success, failed)
        } else {
            // console.log('redy for create api call', postData)
            create_college_batch(router_history, dispatch, postData, success, failed)
        }

    }

    let add_btn_status = false;
    if (BatchName && Course && BatchFrom && BatchTo) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (BatchName !== name || Course !== course_id || BatchFrom !== batch_from || BatchTo !== batch_to) {
        update_btn_status = true
    }

    let general_button = false;
    data ? general_button = update_btn_status : general_button = add_btn_status


    const Generate = (Course, from_date, d3,) => {
        if (Course !== undefined && from_date !== undefined && d3 !== undefined) {
            let course_name = '';
            let batch_name = ''
            const college_data = arr_college_course.find(clg_item => clg_item.id === Course);
            //  console.log(college_data)
            college_data ? course_name = college_data.name : course_name = '';
            let from = from_date.split('-', 1);
            let to = d3.split('-', 1);
            // console.log('testing',d)                                      
            batch_name = from + ' - ' + to + ' - ' + course_name;
            // console.log('testing', batch_name)
            setBatchName(batch_name)
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Create'} Batch</h4>

                <form className={classes.root} noValidate autoComplete="off">

                    <TextField
                        id="standard-basic"
                        select
                        label="Course"
                        value={Course}
                        onChange={txt => { setCourse(txt.target.value); }}
                    >
                        <MenuItem value={''}>Select Course</MenuItem>
                        {
                            arr_college_course.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id} >{name}</MenuItem>
                                )
                            })
                        }

                    </TextField>



                    <TextField
                        id="standard-basic"
                        label="Date From"
                        type="date"
                        disabled={Course ? false : true}
                        value={BatchFrom}
                        onChange={txt => {
                            from_date = txt.target.value
                            setBatchFrom(from_date)
                            if (from_date) {

                                let duration_type = '';
                                let duration = '';

                                const college_data = arr_college_course.find(clg_item => clg_item.id === Course);
                                duration_type = college_data.duration_type;
                                duration = college_data.duration;

                                if (duration_type === 'year') {

                                    let d1 = dayjs(from_date);
                                    let d2 = d1.add(duration, 'year');
                                    let d3 = d2.format("YYYY-MM-DD");
                                    // console.log('testing all kinds of things', d3)
                                    setBatchTo(d3)
                                    Generate(Course, from_date, d3)

                                } else if (duration_type === 'semester') {

                                    let sem = duration * 6;
                                    let d1 = dayjs(from_date);
                                    let d2 = d1.add(sem, 'month');
                                    let d3 = d2.format("YYYY-MM-DD");
                                    // console.log('testing all kinds of things', d3)
                                    setBatchTo(d3)
                                    Generate(Course, from_date, d3)

                                }
                            }

                        }}
                    />

                    <TextField
                        id="standard-basic"
                        label="Date To"
                        type="date"
                        disabled={Course ? false : true}
                        value={BatchTo}
                        onChange={txt => { setBatchTo(txt.target.value); }}
                    />

                    <TextField
                        id="standard-basic"
                        label="Batch Name"
                        value={BatchName}
                        onChange={txt => {
                            setBatchName(txt.target.value)
                        }}
                    />

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default BatchModal
