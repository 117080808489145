import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { create_agency, update_agency } from '../store/action';
import { CircularProgress } from '@material-ui/core'
import Swal from 'sweetalert2';
import { selectFinanceDatas } from '../../finance/store/slice';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function AgentModal({
    modalStatus,
    modalAction,
    data,
    AgentName,
    setAgentName,
    AgencyName,
    setAgencyName,
    PhoneNumber,
    setPhoneNumber,
    SecPhoneNumber,
    setSecPhoneNumber,
    Email,
    setEmail,
    EmailError,
    setEmailError,
    DueAmount,
    setDueAmount,
    FinanceYrId,
    setFinanceYrId
}) {

    const classes = useStyles();

    const handleClose = () => {
        setAgentName('')
        setAgencyName('')
        setPhoneNumber('')
        setSecPhoneNumber('')
        setDueAmount('')
        setEmail('')
        setEmailError('')
        modalAction(false);
    };

    const router_history = useHistory()
    const dispatch = useDispatch()
    const [buttonLoader, setButtonLoader] = useState(false);
    const [PhoneError, setPhoneError] = useState('');
    const [SecPhoneError, setSecPhoneError] = useState('');
    const { financialYrList } = useSelector(selectFinanceDatas)



    // const handleChange = (txt) => {
    //     const value = txt.target.value.replace(/\D/g, "");
    //     setPhoneNumber(value);
    //   };



    let agent_name = '';
    let agency_name = '';
    let phone_number = '';
    let sec_phone_number = '';
    let email = '';
    let due_amount = '';
    let financeId = null
    // previous_due_amount

    if (data) {
        agent_name = data.consultant_name || '';
        agency_name = data.consultancy_name || '';
        phone_number = data.primary_phone || '';
        sec_phone_number = data.secondary_phone || '';
        email = data.email || '';
        due_amount = data.previous_due_amount || '';
        financeId = data.financial_year || ''
    }

    const button_fx = () => {

        setButtonLoader(true);


        const success = () => {
            setButtonLoader(false);
            handleClose()
        }

        const failed = () => {
            setButtonLoader(false);
            handleClose();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Something went wrong!",
            })
        }

        let DuoAmountVal = DueAmount ? DueAmount : 0;

        if (Email) {
            const email_regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
            const phoneno = new RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);

            let phone_status = phoneno.test(PhoneNumber);
            let email_status = email_regex.test(Email);
            let sec_phone_status = phoneno.test(SecPhoneNumber);
            if (email_status && phone_status) {
                let postData = {
                    consultant_name: AgentName,
                    consultancy_name: AgencyName,
                    primary_phone: PhoneNumber,
                    email: Email,
                    previous_due_amount: DuoAmountVal,
                    financial_year: FinanceYrId
                }

                if (SecPhoneNumber) {
                    if (sec_phone_status) {
                        postData.secondary_phone = SecPhoneNumber;
                    }
                }
                setEmailError('')
                if (data) {

                    // console.log('redy for update api call', postData)
                    update_agency(router_history, dispatch, postData, data.id, success, failed)
                } else {

                    // console.log('redy for create api call', postData)
                    create_agency(router_history, dispatch, postData, success, failed)
                }


            } else {
                email_status ? setEmailError('') : setEmailError('Invalid Email Id')
                phone_status ? setPhoneError('') : setPhoneError('Invalid number')
                sec_phone_status ? setSecPhoneError('') : setSecPhoneError('Invalid number')
                setButtonLoader(false)
            }
        } else {
            let postData = {
                consultant_name: AgentName,
                consultancy_name: AgencyName,
                primary_phone: PhoneNumber,
                previous_due_amount: DuoAmountVal,
                financial_year: FinanceYrId
            }

            if (SecPhoneNumber) {
                postData.secondary_phone = SecPhoneNumber;
            }

            if (data) {
                // console.log('redy for update api call', postData)
                update_agency(router_history, dispatch, postData, data.id, success, failed)
            } else {
                // console.log('redy for create api call', postData)
                create_agency(router_history, dispatch, postData, success, failed)
            }
        }
    }


    let add_btn_status = false;
    if (AgentName && AgencyName && PhoneNumber) {
        add_btn_status = true;
    }

    let update_btn_status = false;
    if (AgentName !== agent_name || AgencyName !== agency_name || PhoneNumber !== phone_number || SecPhoneNumber !== sec_phone_number || Email !== email || DueAmount !== due_amount || FinanceYrId !== financeId) {
        update_btn_status = true
    }

    let general_button = false;
    data ? general_button = update_btn_status : general_button = add_btn_status

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Create'} Agency</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Consultant Name"
                        type="text"
                        value={AgentName}
                        onChange={txt => setAgentName(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        label="Consultancy Name"
                        type="text"
                        value={AgencyName}
                        onChange={txt => setAgencyName(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        label="Previous Due Amount"
                        type="number"
                        placeholder="0"
                        value={DueAmount}
                        onChange={txt => setDueAmount(txt.target.value)}
                    />

                    <TextField
                        error={PhoneError ? true : false}
                        id="standard-basic"
                        label="Phone No"
                        type="tel"
                        value={PhoneNumber}
                        onChange={txt => {
                            const value = txt.target.value.replace(/\D/g, "");
                            setPhoneNumber(value);

                        }}
                        helperText={PhoneError}


                    />

                    <TextField
                        error={SecPhoneError ? true : false}
                        id="standard-basic"
                        label="Secondary Phone No"
                        type="tel"
                        value={SecPhoneNumber}
                        onChange={txt => {
                            const value = txt.target.value.replace(/\D/g, "");
                            setSecPhoneNumber(value)
                        }}

                        helperText={SecPhoneError}

                    />

                    <TextField
                        error={EmailError ? true : false}
                        id="standard-basic"
                        label="Email"
                        type="email"
                        value={Email}
                        onChange={txt => setEmail(txt.target.value)}
                        helperText={EmailError}
                    />

                    <TextField
                        id="standard-basic"
                        select
                        label="Financial Year"
                        value={FinanceYrId}
                        onChange={txt => setFinanceYrId(txt.target.value)}
                    >
                        <MenuItem value=''>Choose Financial Year</MenuItem>
                        {
                            financialYrList && financialYrList.map((item, k)=>{
                                // console.log('asdf',item)
                                return (<MenuItem value={item.id}>{item.year}</MenuItem>)
                            })
                        }
                    </TextField>
                    

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {general_button ? (
                        <button
                            onClick={() => button_fx()}
                            className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }
                </div>

            </div>
        </Modal>
    );
}



export default AgentModal
