import React, { useState } from 'react';
import Scrollview from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { fetch_fees, delete_fees } from '../store/action';
import { selectFeesDatas } from '../store/slice';
import { CircularProgress } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import StudentModal from './student_modal';


function ListAgency() {

    let fees_data = useSelector(selectFeesDatas)
    const route_history = useHistory();
    const dispatch = useDispatch();

    const { 
        arr_fees, 
        fees_loader, 
        fees_list_loader, 
        fees_page, 
        fees_pagination, 
        fees_filter, 
        fees_search, 
        fees_filter_from_date, 
        fees_filter_to_date, 
        fees_general_info } = fees_data;

    const ScrollPos = (val) => {
        const { scrollHeight, scrollTop, clientHeight } = val.target;
        let bottom = scrollHeight - scrollTop === clientHeight
        if (bottom) {
            if (fees_pagination) {
                let page_no_val = fees_page + 1;
                fetch_fees(route_history, dispatch, page_no_val, arr_fees, fees_search, fees_filter, fees_filter_from_date, fees_filter_to_date, false, true);
            }
        }

    }
    const delete_fx = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_fees(route_history, dispatch, id);
            }
        })
    }

    //    useEffect(() => {

    //     let api_url = `${FEES_BASE}all/details/`;
    //     const success = (res) => {
    //         const { response } = res;
    //         setTotal(response);
    //     }

    //     Api('get', api_url, '', route_history, success);
    // }, [route_history])


    // let due = Total.total_due_amount;
    // let paid = Total.total_paid_amount;
    // let total_students = Total.total_students;



    const [Modal, setModal] = useState(false);
    const [SelectedFees, setSelectedFees] = useState({});
    const [Created, setCreated] = useState('');


    return (
        <div style={{ display: 'flex' }} className="app-main-list-layout">
            <div className="app-table-layout">
                <table className="app_table_main">
                    <tbody className="app_table_tbody">
                        <tr style={{ fontWeight: 'bold', fontSize: 12 }}>
                            {/* <td style={{ width: 80, padding: 0 }}>Sl No</td> */}
                            <td style={{ width: 120, padding: 0 }}>Date</td>
                            <td style={{ width: 120, padding: 0 }}>Amount</td>
                            <td style={{ width: 120, padding: 0 }}>Student Name</td>
                            <td style={{ width: 120, padding: 0 }}>Student ID</td>
                            {/* <td style={{ width: 120, padding: 0 }}>Collection Place</td> */}
                            <td style={{ width: 120, padding: 0 }}>College</td>
                            <td style={{ width: 120, padding: 0 }}>Description</td>
                            {/* <td style={{ width: 120, padding: 0 }}>Mode Pay</td> */}
                            <td style={{ width: 120, padding: 0 }}>Course</td>
                            <td style={{ width: 120, padding: 0 }}>Admission Fees</td>
                            {/* <td style={{ width: 120, padding: 0 }}>Batch</td> */}
                            <td style={{ width: 120, padding: 0 }}>Action</td>



                        </tr>
                    </tbody>
                </table>

                <Scrollview style={{ height: '70vh', position: 'relative' }}
                    onScroll={e => ScrollPos(e)}
                >
                    {
                        !fees_loader ? (
                            <>
                                {
                                    arr_fees.length > 0 ? (
                                        <>
                                        <table className="app_table_main">
                                            <tbody className="app_table_tbody">
                                                {

                                                    arr_fees.map((item, key) => {
                                                        const { id, created_at, amount, student, course_name, student_name, college_name, description } = item
                                                        return (
                                                            <tr key={key} className="app-table-row">
                                                                {/* <td style={{ width: 80, padding: 0 }}>{id}</td> */}
                                                                <td style={{ width: 120, padding: 0 }}>{created_at.split('T', 1)}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{amount}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{student_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{student}</td>
                                                                {/* <td style={{ width: 120, padding: 0 }}>{collection_place}</td> */}
                                                                <td style={{ width: 120, padding: 0 }}>{college_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>{description}</td>
                                                                {/* <td style={{ width: 120, padding: 0 }}>{mode_of_payment}</td> */}
                                                                <td style={{ width: 120, padding: 0 }}>{course_name}</td>
                                                                <td style={{ width: 120, padding: 0 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18 }}>
                                                                        {item.is_admissionfees ? (<p>✅</p>) : (<p>❌</p>)}
                                                                    </div>
                                                                </td>
                                                                {/* <td style={{ width: 120, padding: 0 }}>{batch_name}</td> */}
                                                                <td style={{ width: 120, padding: 0 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <div
                                                                            onClick={() => {
                                                                                setSelectedFees(item)
                                                                                setModal(true)
                                                                                setCreated(created_at.split('T', 1))
                                                                            }
                                                                            }



                                                                            style={{ textDecoration: 'none', color: '#000' }}>
                                                                            <Visibility style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                        </div>

                                                                        <Delete onClick={() => delete_fx(id)} style={{ fontSize: 16, marginRight: 20, cursor: 'pointer' }} />
                                                                    </div>

                                                                    {/* <div onClick={() => delete_fx(id)} style={{ width: 102, padding: 0, cursor: 'pointer', fontWeight:'bold' }} > Delete</div> */}

                                                                </td>




                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            fees_list_loader && (
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                    <CircularProgress size={30} />
                                                </div>
                                            )
                                        }
                                        </>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>No Data Found</p>
                                        </div>
                                    )
                                }
                            </>



                            // fees_pagination && (
                            //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            //         <CircularProgress size={20} />
                            //     </div>
                            // )


                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                                <CircularProgress size={26} />
                            </div>
                        )
                    }
                </Scrollview>
            </div>





            <div
                style={{
                    backgroundColor: '#CDCDCD',
                    marginLeft: 10,
                    width: 250,
                    borderRadius: 20,
                    fontSize: 12,
                    display: 'flex',
                    height: 250,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {
                    !fees_loader ? (
                        <>
                            <p>Total No Students</p>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>{fees_general_info.total_student} </p>

                            <p style={{ marginTop: 20 }}>Total Paid Amount</p>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>{fees_general_info.total_paid_amount} </p>

                            <p style={{ marginTop: 20 }}>Total Due Amount</p>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>{fees_general_info.due_amount} </p>

                            <p style={{ marginTop: 20 }}>Today's Fees</p>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>{fees_general_info.todays_fees} </p>
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                            <CircularProgress size={18} />
                        </div>
                    )
                }


            </div>


            <StudentModal
                modalStatus={Modal}
                modalAction={setModal}
                data={SelectedFees}
                Created={Created}
            // scrolling={}
            />


        </div>
    )
}

export default ListAgency
