import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import './style.css'
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { create_sub_category, update_sub_category_action } from '../store/action';
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function FinanceModal({
    modalStatus,
    modalAction,
    data,
    CategoryName,
    setCategoryName
}) {

    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();

    const [buttonLoader, setButtonLoader] = useState(false);


    const handleClose = () => {
        setCategoryName('');
        modalAction(false)
    };

    const buttonClick = () => {
        setButtonLoader(true);
        let postData = {
            name: CategoryName
        }

        const success = () => {

            setButtonLoader(false);
            handleClose()
        };
        const failed = () =>{
            setButtonLoader(false)
        }

        if (data) {
            update_sub_category_action(route_history, dispatch, postData, data.id, success, failed)
        } else {
            create_sub_category(route_history, dispatch, postData, success, failed)
        }

    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main">
                <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Create'} Sub Category</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Category Name"
                        type="text"
                        value={CategoryName}
                        onChange={txt => setCategoryName(txt.target.value)}
                    />

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    {CategoryName ? (
                        <button onClick={buttonClick} className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"}>

                            {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                        </button>
                    ) : (<button className="submit-btn disabled-btn">{data ? 'Update' : 'Create'}</button>)
                    }


                </div>

            </div>
        </Modal>
    );
}



export default FinanceModal
