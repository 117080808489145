import Api from '../../store/api';
import { BASE_MAIN } from '../../store/endpoint';
import { update_permission } from './slice';

export const fetch_permission = (history, dispatch) => { 
    let api_url = `${BASE_MAIN}permissions/all/`
    Api('get', api_url, '', history).then(res => {
        const { response } = res
        dispatch(update_permission({
            agency : response.agency,
            agency_detail : response.agency_detail,
            college : response.college,
            commission : response.commission,
            fees : response.fees,
            finanace : response.finanace,
            staff : response.staff,
            student : response.student,
            current_user_type: response.current_user_type
        }))
    })
}

export const fetch_permission_test = (history, dispatch) => { 
    let api_url = `${BASE_MAIN}permissions/all/`
    Api('get', api_url, '', history).then(res => {
        console.log(res)
        // const { response } = res
        // dispatch(update_permission({
        //     agency : response.agency,
        //     agency_detail : response.agency_detail,
        //     college : response.college,
        //     commission : response.commission,
        //     fees : response.fees,
        //     finanace : response.finanace,
        //     staff : response.staff,
        //     student : response.student,
        //     current_user_type: response.current_user_type
        // }))
    })
}