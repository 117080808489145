import React, { useState, useEffect } from 'react'
import { routes } from '../modules/routes'
import { RecordVoiceOver, Work, AccountBalance, School, LocalAtm, Whatshot, Assessment, ExitToApp, AccountCircle, Menu } from '@material-ui/icons'
import '../common-components/styles.css'
import { useLocation, Link } from 'react-router-dom';
import auth from '../modules/login/auth';
import { useHistory } from 'react-router-dom'
import ProfileModal from './profile_modal'
import { BASE_MAIN } from '../store/endpoint';
import { useSelector, useDispatch } from 'react-redux'
import { selectPermissionDatas } from './store/slice'
import Api from '../store/api'
import { fetch_permission } from './store/action';



function Sidebar() {


    const { pathname } = useLocation()

    let current_tab = pathname.split('/')
    if (current_tab.length > 0) {
        current_tab = '/' + current_tab[1]
    } else {
        current_tab = "/"
    }


    const router_history = useHistory();
    const dispatch = useDispatch();

    const [ProfileModalStatus, setProfileModal] = useState(false)
    const [ProfileData, setProfileData] = useState({})
    // const [Hamburger, setHamburger] = useState(false);

    useEffect(() => {
        let api_url = `${BASE_MAIN}staff/view/`;
        const success = (res) => {
            const { response } = res;
            setProfileData(response);
        }
        fetch_permission(router_history, dispatch)
        Api('get', api_url, '', router_history, success);
    }, [router_history, dispatch])

    let profilename = ProfileData.name;
    let email = ProfileData.email;

    const permissions = useSelector(selectPermissionDatas);


    const RenderIcon = ({ name }) => {
        switch (name) {
            case 'Agency': return <RecordVoiceOver style={{ fontSize: '0.8em'}} />;
            case 'Staff': return <Work style={{ fontSize: '0.8em'}} />;
            case 'College': return <AccountBalance style={{ fontSize: '0.8em'}} />;
            case 'Students': return <School style={{ fontSize: '0.8em'}} />;
            case 'Fees': return <LocalAtm style={{ fontSize: '0.8em'}} />;
            case 'Commission': return <Whatshot style={{ fontSize: '0.8em'}} />;
            case 'Finance': return <Assessment style={{ fontSize: '0.8em'}} />;
            default: return null
        }
    }

    return (
        <>

            <div className="sidebar-layout">

                <div className="hamburger" >
                    <Menu />
                </div>




                <div className="profile-menu-layout" onClick={() => setProfileModal(true)}>
                    <AccountCircle style={{ fontSize: 40 }} />
                    <p style={{ fontSize: 14, fontWeight: 'bold', marginTop: 5 }}>{profilename}</p>
                </div>
                {
                    routes.map((item, key) => {
                        const { name, path } = item

                        const MainComponent = () => {
                            let class_name = current_tab === path ? 'sidebar-menu-layout sidebar-menu-layout-active' : 'sidebar-menu-layout'
                            return (
                                <Link to={path} key={key} className={class_name}>
                                    <RenderIcon name={name} />
                                    <p>{name}</p>
                                </Link>
                            )
                        }

                        if (permissions) {
                            const { agency, staff, college, student, fees, commission, finanace } = permissions
                            switch (name) {
                                case 'Agency': return agency ? <MainComponent key={key} /> : null;
                                case 'Staff': return staff ? <MainComponent key={key} /> : null;
                                case 'College': return college ? <MainComponent key={key} /> : null;
                                case 'Students': return student ? <MainComponent key={key} /> : null;
                                case 'Fees': return fees ? <MainComponent key={key} /> : null;
                                case 'Commission': return commission ? <MainComponent key={key} /> : null;
                                case 'Finance': return finanace ? <MainComponent key={key} /> : null;
                                default: return null
                            }
                        } else return <MainComponent key={key} />

                    })
                }


                <div
                    onClick={() => auth.logout(() => { router_history.push('/') })}
                    className="sidebar-menu-layout"
                >
                    <ExitToApp />
                    <p>Logout</p>
                </div>
                <ProfileModal
                    modalStatus={ProfileModalStatus}
                    modalAction={setProfileModal}
                    data={ProfileData}
                    profilename={profilename}
                    email={email}
                />
            </div>
        </>
    )
}

export default Sidebar
